<script setup lang="ts">
import { WorkflowBlockItemFragment } from '@/generated/sdk'
import { Button, Card, Column, Spinner } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useEditorLinks } from '../composables'
import { useBlockOutput } from './useBlockOutput'

const router = useRouter()
const { linkReviewData } = useEditorLinks()
const { runs, fetchOutput } = useBlockOutput()

const props = defineProps<{
  workflowId: string
  workflowBlock: WorkflowBlockItemFragment
}>()

watch(() => props.workflowBlock.blockConfig.id, fetch, { immediate: true })

async function fetch() {
  await fetchOutput(props.workflowBlock.blockConfig.id)
}

async function clickReview() {
  await router.push(linkReviewData(props.workflowId, props.workflowBlock.id))
}
</script>

<template>
  <Button @click="clickReview">Review data</Button>

  <Column v-if="runs?.length" gap="m">
    <template v-for="run in runs" :key="run.id">
      <Card>
        <small v-if="run.output == null" class="dodo-fade-secondary"><em>no output</em></small>
        <small v-else class="dodo-nowrap">{{ run.output }}</small>
      </Card>
    </template>
  </Column>
  <Column v-else-if="runs" justify="center" padding="m">
    <p class="form-description" style="text-align: center">No output items yet</p>
  </Column>
  <Spinner v-else />
</template>
