<script setup lang="ts">
import { BlockIcon } from '@/generated/sdk'
import { IconBullet } from '@/ui/components'
import { computed } from 'vue'
import { useBlockTypes } from '../composables'

const props = defineProps<{
  block: string // blockConfig.block
}>()

const { getBlockTypeDetails } = useBlockTypes()

const blockIcons = new Map([
  [BlockIcon.Template, { name: 'Edit', color: 'blue' }],
  [BlockIcon.Ai, { name: 'GenerativeAI', color: 'purple' }],
  [BlockIcon.Utility, { name: 'Settings', color: 'orange' }],
  [BlockIcon.Logic, { name: 'Settings', color: 'green' }],
  [BlockIcon.Run, { name: 'Play', color: 'grey' }],
] as const)

function getBlockIcon(icon: BlockIcon | undefined) {
  const fallback = { name: 'Info', color: 'grey' } as const
  return (icon ? blockIcons.get(icon) : null) ?? fallback
}

const blockType = computed(() => getBlockTypeDetails(props.block))
const blockIcon = computed(() => getBlockIcon(blockType.value?.icon))
</script>

<template>
  <IconBullet :icon="blockIcon.name" :color="blockIcon.color" />
</template>
