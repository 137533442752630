import { crashPlugin } from '@madxnl/dodo-ui'
import 'reflect-metadata'
import { createApp } from 'vue'
import App from './App.vue'
import { sentryPlugin } from './analytics'
import { auth } from './auth/plugin'
import { navigation } from './navigation'
import { router } from './router'
import { runsPlugin } from './runs'
import { userPlugin } from './user'
import { workflowEditPlugin } from './workflow-edit/plugin'
import { workflowsPlugin } from './workflows/plugin'

const app = createApp(App)

const crash = crashPlugin({
  router,
  onError: (error) => {
    // Ignore ResizeObserver error caused by LastPass
    const resizeObserverError = 'ResizeObserver loop completed with undelivered notifications'
    if (error?.toString().includes(resizeObserverError)) {
      console.warn(`Ignored error: ${resizeObserverError}`) // eslint-disable-line no-console
      return false
    }
  },
})

app.use(sentryPlugin)
app.use(crash)
app.use(navigation)
app.use(auth, { router, navigation })
app.use(userPlugin, { router, navigation })
app.use(workflowsPlugin, { router, navigation })
app.use(workflowEditPlugin, { router, navigation })
app.use(runsPlugin, { router, navigation })
app.use(router)

app.mount('#app')
