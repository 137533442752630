export class Vec2 {
  /* Light-weight 2D vector class */

  constructor(
    readonly x: number,
    readonly y: number,
  ) {}

  add(b: Vec2) {
    return new Vec2(this.x + b.x, this.y + b.y)
  }

  subtract(b: Vec2) {
    return new Vec2(this.x - b.x, this.y - b.y)
  }

  rotate(angleDeg: number) {
    const angle = (angleDeg * Math.PI) / 180
    const cos = Math.cos(angle)
    const sin = Math.sin(angle)
    return new Vec2(this.x * cos - this.y * sin, this.x * sin + this.y * cos)
  }

  scale(s: number) {
    return new Vec2(this.x * s, this.y * s)
  }

  round(roundTo: number) {
    return new Vec2(Math.round(this.x / roundTo) * roundTo, Math.round(this.y / roundTo) * roundTo)
  }

  cross(b: Vec2) {
    return this.x * b.y - this.y * b.x
  }

  dot(b: Vec2) {
    return this.x * b.x + this.y * b.y
  }

  equals(b: Vec2) {
    const e = 1e-6
    return Math.abs(this.x - b.x) < e && Math.abs(this.y - b.y) < e
  }

  angleTo(b: Vec2) {
    // Shortest angle between two vectors
    return Math.atan2(this.cross(b), this.dot(b))
  }

  length() {
    return Math.sqrt(this.x * this.x + this.y * this.y)
  }

  distance(b: Vec2) {
    return this.subtract(b).length()
  }

  normalize() {
    const len = this.length()
    return new Vec2(this.x / len, this.y / len)
  }
}
