<script setup lang="ts">
import { MainPageLayout } from '@/navigation/components'
import { DisplayDate, RunStatus, ShowMoreButton, TwinIcon, VimeoVideo } from '@/ui/components'
import { useConfirmDelete, useSimpleMessage } from '@/ui/composables'
import { useEditorLinks } from '@/workflow-edit'
import { workflowLinks } from '@/workflows/routes'
import { Button, Card, Chip, Column, Icon, Row, Spinner, TextInput } from '@madxnl/dodo-ui'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import RunWorkflowModal from './RunWorkflowModal.vue'
import { useWorkflows } from './useWorkflows'

// Workflow overview page

const { workflows, deleteWorkflow, showMore, createNew, search } = useWorkflows()
const { confirmDelete } = useConfirmDelete()
const { showMessage } = useSimpleMessage()
const { linkWorkflowEdit } = useEditorLinks()
const router = useRouter()
const workflowIdToRun = ref<string | null>(null)

const videoSrc = 'https://player.vimeo.com/video/100902001?h=dcaa89d3e0&autoplay=1&title=0&byline=0&portrait=0'

async function clickDelete(workflowId: string) {
  const workflow = workflows.value?.find((workflow) => workflow.id === workflowId)
  if (await confirmDelete(workflow?.name ?? '')) {
    await deleteWorkflow(workflowId)
    showMessage('Workflow deleted')
  }
}

async function clickNewWorkflow() {
  const newWorkflow = await createNew()
  await router.push(linkWorkflowEdit(newWorkflow.id))
}

async function clickViewData(workflowId: string) {
  await router.push(workflowLinks.workflowData(workflowId))
}

async function clickEdit(workflowId: string) {
  await router.push(linkWorkflowEdit(workflowId))
}
</script>

<template>
  <MainPageLayout>
    <Row justify="between">
      <h1>Workflows</h1>

      <Row>
        <TextInput v-model="search" type="search" placeholder="Search" />
        <Button variant="solid" color="primary" @click="clickNewWorkflow"> <TwinIcon icon="Plus" />New </Button>
      </Row>
    </Row>

    <Spinner v-if="workflows == null" />

    <template v-else-if="workflows.length === 0">
      <Column v-if="search">
        <p>No results found for '{{ search }}'</p>
      </Column>
      <Column v-else align="center">
        <br />
        <VimeoVideo :src="videoSrc" />
        <br />
        <h3>You have no workflows yet. What are you waiting for?</h3>
        <Button @click="clickNewWorkflow">
          <Icon name="Add" />
          Create your first workflow
        </Button>
      </Column>
    </template>

    <template v-else-if="workflows">
      <Column gap="m">
        <Card
          v-for="workflow in workflows"
          :key="workflow.id"
          padding="l"
          gap="s"
          hoverable
          @click="clickEdit(workflow.id)"
        >
          <Row>
            <Column gap="s" grow>
              <Row gap="m">
                <h2>{{ workflow.name }}</h2>
                <Chip v-if="workflow.draft">Draft</Chip>
              </Row>
              <Column gap="m">
                <Row :class="$style.disabled" gap="xl">
                  <Column gap="0">
                    <p>Created</p>

                    <DisplayDate :date="Number(workflow.createdAt)" />
                  </Column>
                  <Column gap="0">
                    <p>Last edited</p>

                    <DisplayDate :date="Number(workflow.createdAt)" />
                  </Column>
                  <Column gap="0">
                    <p>Runs</p>

                    {{ workflow.numberOfRuns }}
                  </Column>
                  <Column gap="0">
                    <p>Last run</p>

                    <Row>
                      <DisplayDate :date="Number(workflow.latestRunDate)" />
                      <RunStatus v-if="workflow.latestRunStatus" :run-status="workflow.latestRunStatus" />
                    </Row>
                  </Column>
                </Row>
                <Row gap="l">
                  <Button variant="link" color="primary" @click.stop="workflowIdToRun = workflow.id">
                    <TwinIcon icon="Play" />
                    Run
                  </Button>
                  <Button variant="link" @click.stop="clickEdit(workflow.id)">
                    <TwinIcon icon="Edit" />
                    Edit
                  </Button>
                  <Button variant="link" @click.stop="clickViewData(workflow.id)">
                    <TwinIcon icon="NPS" />
                    View data
                  </Button>
                  <Button variant="link" color="danger" @click.stop="clickDelete(workflow.id)">
                    <TwinIcon icon="Delete" />
                    Delete
                  </Button>
                </Row>
                <p v-if="workflow.description">
                  {{ workflow.description }}
                </p>
              </Column>
            </Column>
            <Row>
              <TwinIcon icon="ChevronRight" />
            </Row>
          </Row>
        </Card>
      </Column>

      <ShowMoreButton :show-more="showMore" variant="clear" />
    </template>
  </MainPageLayout>

  <RunWorkflowModal :open="!!workflowIdToRun" :workflow-id="workflowIdToRun" @close="workflowIdToRun = null" />
</template>

<style module>
.disabled {
  color: var(--grey-4-disabled);
}
</style>
