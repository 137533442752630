<script setup lang="ts">
import { WorkflowBlockItemFragment } from '@/generated/sdk'
import { useDraggable } from '@/ui/composables'
import { computed, ref } from 'vue'
import { useDragToConnect, useEditorState, useEditorView, useWorkflowEditor } from '../composables'
import { EditorLine } from '../connect'
import EditorBlockCard from './EditorBlockCard.vue'
import EditorBlockConnector from './EditorBlockConnector.vue'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment
  disabled: boolean
}>()

const { endDragConnect, dragConnectState } = useDragToConnect()
const { selectedBlock, gridSize } = useWorkflowEditor()
const { zoomScale } = useEditorView()
const { getBlockPosition, setBlockPosition } = useEditorState()

const activateConnectors = ref(false)

const isDragConnectTarget = computed(
  () => dragConnectState.value && dragConnectState.value.blockId !== props.workflowBlock.id,
)

// const validationMessages = computed(() => {
//   if (!validationResult.value) return []
//   const { errors } = validationResult.value
//   const thisBlockErrors = errors.filter((e) => e.blockConfig.id === props.blockConfig.id)
//   return thisBlockErrors
// })

const position = computed(() => {
  const [x, y] = getBlockPosition(props.workflowBlock.id)
  return [x, y] as [number, number]
})

const getPositionCss = computed(() => {
  const [x, y] = position.value
  return `left: ${x}px; top: ${y}px;`
})

const el = ref<HTMLElement>()
const loopEl = ref<HTMLElement>()
const id = computed(() => props.workflowBlock.id)

async function onClick(e: PointerEvent) {
  selectedBlock.value = props.workflowBlock
  if (props.disabled) return
  if (dragConnectState.value) {
    await endConnect()
  } else {
    blockDrag.startDrag(e)
  }
}

async function onPointerup() {
  if (dragConnectState.value) {
    await endConnect()
  }
}

async function endConnect() {
  await endDragConnect(props.workflowBlock)
}

const blockDrag = useDraggable({
  drag(dx, dy) {
    const [x, y] = position.value
    const newX = x + dx / zoomScale.value
    const newY = y + dy / zoomScale.value
    setBlockPosition(props.workflowBlock.id, [newX, newY])
  },
  end() {
    // Snap to grid
    const [x, y] = position.value
    const [newX, newY] = [Math.round(x / gridSize) * gridSize, Math.round(y / gridSize) * gridSize]
    setBlockPosition(props.workflowBlock.id, [newX, newY])
  },
})

const isDragging = computed(() => {
  // allows some movement while clicking buttons inside block
  return blockDrag.distance.value > 5
})

const loopConfig = computed(() => props.workflowBlock.blockConfig.loop?.blockConfig)
const blockCard = ref<InstanceType<typeof EditorBlockCard>>()
const loopCard = ref<InstanceType<typeof EditorBlockCard>>()
const inputConnector = computed(() => blockCard.value?.inputConnector)
const mainOutputConnectors = computed(() => blockCard.value?.outputConnectors)
const loopOutputConnector = ref<InstanceType<typeof EditorBlockConnector>>()
const outputConnectors = computed(() =>
  loopOutputConnector.value ? [loopOutputConnector.value] : mainOutputConnectors.value,
)

const points = computed(() => {
  const coords = mainOutputConnectors.value?.[0]?.coords
  const endCoords = loopCard.value?.inputConnector?.coords
  if (!coords || !endCoords) return null
  const start = { x: coords.x, y: coords.y, angle: 0 }
  const end = { x: endCoords.x, y: endCoords.y, angle: 180 }
  return { start, end }
})

const size = () => {
  const width = Math.max(loopEl.value?.clientWidth ?? 0, el.value?.clientWidth ?? 0)
  const height = Math.max(loopEl.value?.clientHeight ?? 0, el.value?.clientHeight ?? 0)
  return { width, height }
}

defineExpose({ inputConnector, outputConnectors, id, size, position })
</script>

<template>
  <EditorLine v-if="loopConfig && points" :points="points" disabled />

  <div
    ref="el"
    :class="[$style.node, selectedBlock === workflowBlock && $style.nodeSelected]"
    :style="getPositionCss"
    @mouseover="activateConnectors = true"
    @mouseleave="activateConnectors = false"
  >
    <EditorBlockCard
      ref="blockCard"
      :class="[$style.card, isDragConnectTarget ? $style.dragTarget : '']"
      :style="isDragging ? 'pointer-events: none;' : ''"
      :workflow-block="workflowBlock"
      :block-config="workflowBlock.blockConfig"
      :selected="selectedBlock === workflowBlock"
      :disabled="disabled"
      :disable-output="!!loopConfig"
      :activate-connectors="activateConnectors"
      @pointerdown.left.stop="onClick"
      @pointerup="onPointerup()"
    />
    <template v-if="loopConfig">
      <div ref="loopEl" :class="$style.looparea">
        <EditorBlockCard
          ref="loopCard"
          :class="$style.card"
          :workflow-block="null"
          :block-config="loopConfig"
          :selected="false"
          :disabled="true"
          :disable-hover="true"
        />
      </div>
      <div :class="$style.looplabel">
        <span>After loop</span>
      </div>
      <EditorBlockConnector
        ref="loopOutputConnector"
        :disabled="disabled"
        :workflow-block="workflowBlock"
        :hover-state="activateConnectors"
      />
    </template>
  </div>
</template>

<style module>
.card:not(:disabled) {
  cursor: pointer;
}
.card {
  z-index: 1;
}
.node {
  position: absolute;
  display: grid;
  justify-items: center;
}
.looparea {
  outline: rgba(0, 0, 0, 0.1) solid 1px;
  background: rgba(0, 0, 0, 0.02);
  padding: 64px 32px;
  margin: -16px 0;
  border-radius: 32px;
}
.nodeSelected .looparea {
  outline: rgba(0, 0, 0, 0.15) solid 1px;
  background: rgba(0, 0, 0, 0.04);
}
.looplabel {
  background: var(--blue-light);
  color: var(--blue-dark);
  font-weight: 500;
  border-radius: 16px;
  padding: 4px 16px;
  border: 1px solid color-mix(in lab, var(--blue-light) 80%, var(--blue-dark));
}
.dragTarget {
  box-shadow: var(--card-shadow-hover);
  outline: 1px solid var(--grey-4-disabled);
}
</style>
