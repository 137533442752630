import { useApiClient } from '@/api'
import { WorkflowBlockItemFragment } from '@/generated/sdk'
import { ref } from 'vue'

export function useWorkflowBlock() {
  const workflowBlock = ref<WorkflowBlockItemFragment>()
  const { client } = useApiClient()

  async function fetch(id: string) {
    const res = await client.getWorkflowBlock({ id })
    workflowBlock.value = res.workflowBlock[0]
  }

  async function runWorkflowBlock(inputString: string) {
    if (!workflowBlock.value) return
    const blockConfigId = workflowBlock.value.blockConfig.id
    const input = tryParseJson(inputString)
    await client.runByBlockConfigId({ blockConfigId, input })
  }

  return { workflowBlock, fetch, runWorkflowBlock }
}

const tryParseJson = (value: string) => {
  try {
    return JSON.parse(value) as unknown
  } catch (e) {
    return value
  }
}
