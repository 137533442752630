<script lang="ts" setup>
import { EditorBlockIcon } from '@/workflow-edit/block'
import { Card, Column, Row } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { TwinIcon } from '.'
import { useUtils } from '../composables'

const { truncate } = useUtils()

const props = defineProps<{
  listStyle?: 'block'
  groupedOptions?: {
    groupName: string | null
    items: { name: string; id: string; currentValue?: boolean; blockType?: string }[]
  }[]
}>()

const selectedOption = ref(0)
const searchText = ref('')

watch(searchText, () => (selectedOption.value = 0), { immediate: true })

const filteredGroupOptions = computed(() => {
  const searchString = searchText.value.trim().toLowerCase()
  const filtered = props.groupedOptions?.map((group) => {
    if (!searchString) return group
    const items = group.items.filter((option) => option.name.toLowerCase().includes(searchString))
    return { ...group, items }
  })
  const nonEmptyGroups = filtered?.filter((g) => g.items.length > 0)
  return nonEmptyGroups
})

const filteredOptions = computed(() => filteredGroupOptions.value?.flatMap((group) => group.items) || [])
const selectedItem = computed(() => filteredOptions.value?.[selectedOption.value])

const emit = defineEmits<{
  select: [id: string]
}>()

function select(id?: string) {
  if (!id) return
  emit('select', id)
}

const vFocus = {
  mounted: (el: HTMLInputElement) => el.focus(),
}

function moveDown() {
  selectedOption.value = Math.min(selectedOption.value + 1, filteredOptions.value.length - 1)
}

function moveUp() {
  selectedOption.value = Math.max(selectedOption.value - 1, 0)
}
</script>

<template>
  <input
    v-model="searchText"
    v-focus
    :class="['dodo-formfield', $style.search]"
    type="text"
    placeholder="Type to search"
    name="Search"
    @keydown.enter="select(selectedItem?.id)"
    @keydown.down.prevent="moveDown"
    @keydown.up.prevent="moveUp"
  />

  <hr />

  <Column v-if="filteredGroupOptions?.length === 0" padding="m">
    <p>Nothing found</p>
  </Column>

  <Column v-else gap="0" :class="$style.list">
    <template v-for="(group, i) in filteredGroupOptions" :key="group.groupName">
      <div v-if="i" :class="$style.separator"><hr /></div>
      <h4 v-if="group.groupName != null" :class="$style.heading">{{ group.groupName }}</h4>

      <template v-for="item in group.items" :key="item.id">
        <div :class="[$style.option, item === selectedItem && $style.active]" @click.prevent="select(item.id)">
          <Card v-if="listStyle === 'block'" gap="0" padding="0">
            <div :class="$style.titleGrid">
              <EditorBlockIcon :block="item.blockType || ''" />
              <h4>{{ truncate(item.name, 38) }}</h4>
            </div>
          </Card>

          <Row v-else @click="select(item.id)">
            <EditorBlockIcon v-if="item.blockType" :block="item.blockType" />
            <span style="flex: 1">{{ item.name }}</span>
            <TwinIcon v-if="item.currentValue" icon="Check" />
          </Row>
        </div>
      </template>
    </template>
  </Column>
</template>

<style module>
.list {
  padding: 8px 0;
  max-height: 300px;
  overflow: auto;
}

.separator {
  padding: 8px 16px;
}
.heading {
  padding: 8px 16px;
}
.search {
  margin: 0 16px 16px 16px;
}

.option {
  cursor: pointer;
  padding: 8px 16px;
}
.option:hover,
.option.active {
  background: var(--grey-2-lines);
}

.titleGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  padding: 16px;
}

.input {
  width: 100%;
  cursor: pointer;
  background-color: color-mix(in lab, var(--dodo-color-info) 5%, transparent);
}
</style>
