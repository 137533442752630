import { computed, reactive } from 'vue'

const state = reactive({
  openRunBlockModal: false,
})

export function useOpenRunBlockModal() {
  function showRunBlockModal() {
    state.openRunBlockModal = true
  }

  function closeRunBlockModal() {
    state.openRunBlockModal = false
  }

  const open = computed(() => state.openRunBlockModal)

  return {
    open,
    showRunBlockModal,
    closeRunBlockModal,
  }
}
