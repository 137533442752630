<script setup lang="ts">
import { useCurrentUser } from '@/auth/composables'
import { DisplayDate, TwinIcon } from '@/ui/components'
import { useConfirmDelete, useSimpleMessage } from '@/ui/composables'
import { Button, Card, Column, Grid, Row, Spinner } from '@madxnl/dodo-ui'
import { onMounted, ref } from 'vue'
import { MemberInviteModal } from '.'
import { useUserOrganization } from '../composables'

const { organization, fetch, deleteInvite, deleteMember, resendInvite } = useUserOrganization()
const showMemberInvite = ref(false)
const { confirmDelete } = useConfirmDelete()
const { showMessage } = useSimpleMessage()
const { currentUser } = useCurrentUser()

onMounted(fetch)

type Organization = NonNullable<typeof organization.value>
type Member = Organization['users'][0]
type Invitee = Organization['invitees'][0]

async function clickDeleteMember(member: Member) {
  if (member.email === currentUser.value?.email) {
    return showMessage('You cannot remove yourself', { type: 'danger' })
  }
  if (await confirmDelete(member.email)) {
    await deleteMember(member.id)
    showMessage('Member removed')
  }
}

async function clickDeleteInvite(invitee: Invitee) {
  if (await confirmDelete(invitee.email)) {
    await deleteInvite(invitee.id)
    showMessage('Invite removed')
  }
}

async function clickResend(invitee: Invitee) {
  await resendInvite(invitee.id)
  showMessage('Invite sent')
}
</script>

<template>
  <Column gap="l">
    <Row>
      <Button @click="showMemberInvite = true"><TwinIcon icon="Plus" />Invite</Button>
    </Row>

    <Grid v-if="organization" column-size="m">
      <Card v-for="member in organization.users" :key="member.id">
        <Row>
          <Column gap="s" grow>
            <h4 v-if="member.firstName || member.lastName">{{ member.firstName }} {{ member.lastName }}</h4>
            <h4 v-else>-</h4>
            <p>{{ member.email }}</p>
            <p class="form-description">Joined <DisplayDate :date="parseInt(member.createdAt)" /></p>
          </Column>

          <Button color="danger" variant="link" square aria-label="Remove member" @click="clickDeleteMember(member)">
            <TwinIcon icon="Delete" />
          </Button>
        </Row>
      </Card>
      <Card v-for="invite in organization.invitees" :key="invite.id">
        <Row>
          <Column gap="s" grow>
            <Row>
              <h4 v-if="invite.name">{{ invite.name }}</h4>
              <p class="dodo-fade-secondary">(invite pending)</p>
            </Row>
            <p>{{ invite.email }}</p>
            <p class="form-description">Sent <DisplayDate :date="parseInt(invite.createdAt)" /></p>
          </Column>

          <Button variant="link" square @click="clickResend(invite)">
            <TwinIcon icon="Redo" />
          </Button>

          <Button color="danger" variant="link" square aria-label="Remove invite" @click="clickDeleteInvite(invite)">
            <TwinIcon icon="Delete" />
          </Button>
        </Row>
      </Card>
    </Grid>
    <Spinner v-else />
  </Column>

  <MemberInviteModal :open="showMemberInvite" @close="showMemberInvite = false" @invite-created="fetch" />
</template>
