import { useApiClient } from '@/api'
import { BlockConfigFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { useUtils } from '@/ui/composables'
import { InjectionKey, inject, provide, reactive, ref } from 'vue'

const workflow = ref<WorkflowDetailsFragment>()

const key: InjectionKey<ReturnType<typeof createWorkflowDetails>> = Symbol('workflowDetails')

export function useWorkflowDetails() {
  const composable = inject(key, null)
  if (!composable) throw new Error('useWorkflowDetails() is used without provider')
  return composable
}

export function provideWorkflowDetails() {
  if (inject(key, null)) throw new Error('useWorkflowDetails() is provided multiple times')
  const composable = createWorkflowDetails()
  provide(key, composable)
  return composable
}

export function createWorkflowDetails() {
  const { client } = useApiClient()
  const { debounce } = useUtils()

  async function fetchWorkflow(workflowId: string) {
    if (workflow.value && workflowId !== workflow.value.id) {
      workflow.value = undefined
    }
    const response = await client.workflowDetails({ id: workflowId })
    workflow.value = response.workflow[0] ? reactive(response.workflow[0]) : undefined
  }

  async function refreshWorkflow() {
    if (!workflow.value) throw new Error('Workflow not loaded')
    await fetchWorkflow(workflow.value.id)
  }

  async function saveWorkflow() {
    // Only save changes to value/text fields, as workflow structure is updated in real time
    const modified = workflow.value
    if (!modified) throw new Error('Workflow not loaded')

    const mapBlockConfig = (config: BlockConfigFragment) => ({
      id: config.id,
      block: config.block,
      workflow: config.workflow ? { id: config.workflow.id } : null,
      arguments: config.arguments.map((arg) => ({
        id: arg.id,
        name: arg.name,
        value: arg.value,
        argumentType: arg.argumentType,
      })),
    })

    await client.updateWorkflow({
      input: {
        name: modified.name,
        description: modified.description,
        id: modified.id,
        editorState: modified.editorState,
        workflowBlocks: modified.workflowBlocks.map((workflowBlock) => ({
          id: workflowBlock.id,
          name: workflowBlock.name,
          condition: workflowBlock.condition,
          blockType: workflowBlock.blockType,
          blockConfig: {
            ...mapBlockConfig(workflowBlock.blockConfig),
            loop: workflowBlock.blockConfig.loop?.blockConfig
              ? {
                  id: workflowBlock.blockConfig.loop.id,
                  blockConfig: {
                    ...mapBlockConfig(workflowBlock.blockConfig.loop.blockConfig),
                  },
                }
              : null,
          },
        })),
        blockConfigs: modified.blockConfigs.map((blockConfig) => ({
          ...mapBlockConfig(blockConfig),
        })),
        result: modified.result.map((arg) => ({
          id: arg.id,
          name: arg.name,
          value: arg.value,
          argumentType: arg.argumentType,
        })),
      },
    })
  }

  const debouncedSave = debounce(saveWorkflow, { delayMs: 5000 })

  function triggerSaveWorkflow() {
    debouncedSave.trigger()
  }

  async function saveWorkflowImmediate() {
    debouncedSave.cancel()
    await saveWorkflow()
  }

  async function savePendingChanges() {
    // If auto-save is pending, save immediately
    if (debouncedSave.isPending.value) {
      await saveWorkflowImmediate()
    }
  }

  return { workflow, fetchWorkflow, refreshWorkflow, triggerSaveWorkflow, saveWorkflowImmediate, savePendingChanges }
}
