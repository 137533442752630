import { reactive, readonly } from 'vue'

const state = reactive({
  open: false,
  name: '',
  resolve: null as null | ((value: boolean) => void),
})

const currentDialog = readonly(state)

export function useConfirmDelete() {
  // This service is used to show a dialog with a title, message and a list of actions

  async function confirmDelete(name: string) {
    if (state.open) throw new Error('Dialog already open')
    state.name = name
    state.open = true
    const result = await new Promise((resolve) => (state.resolve = resolve))
    state.open = false
    return result
  }

  return {
    confirmDelete,
    currentDialog,
  }
}
