import { WorkflowInputArgumentFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'
import { typeMapping, useWorkflowSettings } from './useWorkflowSettings'

const name = ref('')
const description = ref('')
const required = ref(false)
const type = ref('')
const data = reactive({ name, description, type, required })
const argumentId = ref('')

export function useWorkflowSettingForm() {
  const { workflowInputArguments, updateArgument, getTypeName } = useWorkflowSettings()

  function nameValidator(name?: string) {
    const nameTaken = !!workflowInputArguments?.value.some((a) => a.name === data.name && a.id !== argumentId.value)
    if (nameTaken) return 'The title must be unique.'
    return undefined
  }

  const { errors, validate } = useValidation({
    name: { value: name, required: true, maxLen: 50, validators: [nameValidator] },
    description: { value: description },
    type: { value: type, required: true },
  })

  function reset() {
    name.value = ''
    description.value = ''
    type.value = ''
    required.value = false
  }

  function load(setting: WorkflowInputArgumentFragment) {
    argumentId.value = setting.id
    name.value = setting.name
    description.value = setting.description
    type.value = getTypeName(setting) || ''
    required.value = setting.required
  }

  async function submit() {
    const isValid = await validate()
    if (!isValid) return false

    const typeConfig = typeMapping[data.type as keyof typeof typeMapping]

    await updateArgument({
      id: argumentId.value,
      name: data.name,
      description: data.description,
      required: data.required,
      argumentType: typeConfig.argumentType,
      inputType: typeConfig.inputType,
    })
    return true
  }

  return { data, load, submit, errors, reset }
}
