<script setup lang="ts">
import { FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { countries } from 'countries-list'

interface Address {
  street1: string
  street2: string
  zip: string
  city: string
  state: string
  country: string
}

defineProps<{
  address: Address
  errors: Partial<Record<keyof Address, string>>
}>()

const countryOptions = Object.entries(countries)
  .map(([code, country]) => ({
    label: country.name,
    value: code,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))
</script>

<template>
  <FormItem label="Street address" :error="errors.street1">
    <TextInput v-model="address.street1" name="address1" />
  </FormItem>

  <FormItem label="Street address line 2 (optional)" :error="errors.street2">
    <TextInput v-model="address.street2" name="address2" />
  </FormItem>

  <Row>
    <FormItem label="Zip or postal code" :error="errors.zip" style="min-width: 0" class="dodo-grow">
      <TextInput v-model="address.zip" name="zip code" />
    </FormItem>

    <FormItem label="City" :error="errors.city" style="min-width: 0" class="dodo-grow">
      <TextInput v-model="address.city" name="city" />
    </FormItem>
  </Row>

  <FormItem label="State or region (optional)" :error="errors.state">
    <TextInput v-model="address.state" name="State" />
  </FormItem>

  <FormItem label="Country" :error="errors.country">
    <Select v-model="address.country" :options="countryOptions" />
  </FormItem>
</template>
