<script lang="ts" setup>
import { NavbarItem } from '@/navigation/components'
import { DisplayCurrency } from '@/ui/components'
import { Spinner } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { useUserOrganization } from '@/user/composables'

const { organization, fetch } = useUserOrganization()

onMounted(fetch)
</script>

<template>
  <NavbarItem :class="$style.disabled">
    <DisplayCurrency v-if="organization" :amount="Number(organization?.subscriptionBudget)" />
    <Spinner v-else />
  </NavbarItem>
</template>

<style module>
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
</style>
