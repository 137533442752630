<script setup lang="ts">
import { useApiClient } from '@/api'
import { FileDetailsFragment } from '@/generated/sdk'
import { FileUploadModal, TwinIcon } from '@/ui/components'
import { Button, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'

const { client } = useApiClient()

const props = defineProps<{
  multiple?: boolean
  modelValue: undefined | null | string | string[]
  placeholder?: string
}>()

const emit = defineEmits<{
  'update:modelValue': [value: null | string | string[]]
}>()

const ids = ref<string[]>([])
const fileDetails = ref<FileDetailsFragment[]>()
const fileuploadModalOpen = ref(false)

const fileNames = computed(() => fileDetails.value?.map((file) => file.originalName).join(', ') ?? '')

watch(
  () => props.modelValue,
  async () => {
    if (props.modelValue === '') return
    const array = Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]
    ids.value = array.map((x) => x!).filter((x) => x)
    if (ids.value && ids.value.length > 0 && fileDetails.value == null) await fetch()
  },
  { immediate: true },
)

async function fetch() {
  if (ids.value.length) {
    if (!client.files) throw new Error(JSON.stringify(ids))
    const details = await client.files({ input: ids.value?.map((id) => ({ id })) })
    fileDetails.value = details.file
  } else {
    fileDetails.value = []
  }
}

async function setFileIds(newFiles: FileDetailsFragment[]) {
  if (newFiles.length === 0) return
  fileDetails.value = newFiles
  ids.value = newFiles.map((file) => file.id)
  fileuploadModalOpen.value = false
  emitValue()
}

function clearFiles() {
  ids.value = []
  fileDetails.value = undefined
  emitValue()
}

function emitValue() {
  const value = props.multiple ? ids.value : ids.value?.[0]
  emit('update:modelValue', value || null)
}
</script>

<template>
  <Row>
    <TextInput :class="$style.fileInput" :model-value="fileNames" :placeholder @click="fileuploadModalOpen = true" />
    <Button square @click="clearFiles">
      <TwinIcon icon="Cross" />
    </Button>
  </Row>

  <FileUploadModal
    :multiple="multiple ?? false"
    :open="fileuploadModalOpen"
    @close="fileuploadModalOpen = false"
    @files-created="setFileIds"
  />
</template>

<style module>
.fileInput {
  flex: 1;
  cursor: pointer;
}
</style>
