import { RouteLocationRaw } from 'vue-router'
import { editorRoutes } from '../routes'
import { useEditorBreadcrumbs } from './useEditorBreadcrumbs'

export function useEditorLinks() {
  const crumbs = useEditorBreadcrumbs()

  function linkWorkflowEdit(workflowId: string): RouteLocationRaw {
    const query = crumbs.reset()
    return { name: editorRoutes.workflowEdit.name, params: { workflowId }, query }
  }

  function linkSubworkflowEdit(parentWorkflowId: string, subworkflowId: string): RouteLocationRaw {
    const query = crumbs.append(parentWorkflowId)
    return { name: editorRoutes.workflowEdit.name, params: { workflowId: subworkflowId }, query }
  }

  function linkParentWorkflowEdit(workflowId: string): RouteLocationRaw {
    const query = crumbs.popAfter(workflowId)
    return { name: editorRoutes.workflowEdit.name, params: { workflowId }, query }
  }

  function linkBackToWorkflowEdit(workflowId: string): RouteLocationRaw {
    const query = crumbs.currentQuery()
    return { name: editorRoutes.workflowEdit.name, params: { workflowId }, query }
  }

  function linkReviewData(workflowId: string, workflowBlockId: string): RouteLocationRaw {
    const query = crumbs.currentQuery()
    return { name: editorRoutes.reviewData.name, params: { workflowId, workflowBlockId }, query }
  }

  return { linkWorkflowEdit, linkReviewData, linkParentWorkflowEdit, linkSubworkflowEdit, linkBackToWorkflowEdit }
}
