import { useApiClient } from '@/api'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'

const email = ref('')
const firstName = ref('')
const lastName = ref('')
const phoneNumber = ref('')
const data = reactive({ email, firstName, lastName, phoneNumber })
const userId = ref('')
const saved = ref(false)

export function useProfileForm() {
  const { client } = useApiClient()
  const { errors, validate } = useValidation({
    email: { value: email, required: true, isEmail: true },
    firstName: { value: firstName, required: true },
    lastName: { value: lastName },
    phoneNumber: { value: phoneNumber },
  })

  async function load() {
    const res = await client.me()
    const user = res.me

    userId.value = user.id
    email.value = user.email
    firstName.value = user.firstName
    lastName.value = user.lastName ?? ''
    phoneNumber.value = user.phoneNumber ?? ''
  }

  async function submit() {
    saved.value = false
    const isValid = await validate()
    if (!isValid) return

    await client.updateProfile({ data: { ...data, id: userId.value } })
    saved.value = true
  }

  return { data, load, submit, errors, saved }
}
