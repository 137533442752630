import { App } from 'vue'
import { createNavEntries } from './composables/useNavEntries'

export function createNavigation() {
  const navEntries = createNavEntries()

  return {
    navEntries,

    install(app: App) {
      // Provide global nav entries for injection
      navEntries.install(app)
    },
  }
}

export type Navigation = ReturnType<typeof createNavigation>

export const navigation = createNavigation()
