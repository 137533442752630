<script lang="ts" setup>
import { useSignupForm } from '../composables'
import { AddressFields } from '@/user'
import { Column, Row } from '@madxnl/dodo-ui'

const { organizationAddress, billingAddress, organizationAddressSameAsBilling } = useSignupForm()
</script>

<template>
  <Column gap="l">
    <h3>Billing address</h3>
    <AddressFields :address="billingAddress.data" :errors="billingAddress.errors" />

    <h3>Company address</h3>
    <Row align="center" gap="m">
      <input id="organizationAddressSameAsBilling" v-model="organizationAddressSameAsBilling" type="checkbox" />
      <label for="organizationAddressSameAsBilling">Same as billing address</label>
    </Row>

    <template v-if="!organizationAddressSameAsBilling">
      <AddressFields :address="organizationAddress.data" :errors="organizationAddress.errors" />
    </template>
  </Column>
</template>
