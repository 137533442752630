import { reactive } from 'vue'

let mousePosition: ReturnType<typeof createMousePosition> | null = null

function createMousePosition() {
  const mousePosition = reactive({ x: 0, y: 0 })

  window.addEventListener('mousemove', (e) => {
    mousePosition.x = e.clientX
    mousePosition.y = e.clientY
  })

  return { mousePosition }
}

export function useMousePosition() {
  if (!mousePosition) {
    mousePosition = createMousePosition()
  }
  return mousePosition
}
