import { useApiClient } from '@/api'
import { useValidateEmail } from '@/auth/composables'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'

const email = ref('')
const name = ref('')
const data = reactive({ email, name })

export function useMemberInviteForm() {
  const { client } = useApiClient()
  const { validateEmail } = useValidateEmail()

  const { errors, validate } = useValidation({
    email: { value: email, required: true, isEmail: true, validators: [validateEmail] },
    name: { value: name },
  })

  async function submit() {
    const isValid = await validate()
    if (!isValid) return

    const result = await client.createInvite({ input: { ...data } })

    email.value = ''
    name.value = ''

    return result.createInvitee
  }

  return { data, submit, errors }
}
