import { reactive, computed } from 'vue'

type MessageType = 'success' | 'danger'

const state = reactive({
  message: null as null | {
    text: string
    type: MessageType
  },
  timer: null as null | ReturnType<typeof setTimeout>,
})

export function useSimpleMessage() {
  function showMessage(text: string, opts?: { type?: MessageType; duration?: number }) {
    const type = opts?.type ?? 'success'
    const duration = opts?.duration ?? 3000
    state.message = { text, type }
    if (state.timer != null) clearTimeout(state.timer)
    state.timer = setTimeout(() => {
      state.message = null
    }, duration)
  }

  const currentMessage = computed(() => state.message)

  return {
    currentMessage,
    showMessage,
  }
}
