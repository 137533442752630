<script lang="ts" setup>
import { onErrorCaptured, reactive } from 'vue'
import { Button, Column, Row } from '@madxnl/dodo-ui'

const state = reactive({ offline: false })

onErrorCaptured((err) => {
  if (err instanceof Error && err.message === 'Network request failed' && window.navigator.onLine !== true) {
    state.offline = true
    return false
  }
  return true
})

function retry() {
  state.offline = false
}
</script>

<template>
  <Row v-if="state.offline" justify="center" padding="m">
    <Column gap="xs">
      <br />
      <h2>Connect to the internet</h2>
      <p>You're offline. Check your connection.</p>
      <br />
      <Row justify="center">
        <Button color="primary" variant="solid" @click="retry">Retry</Button>
      </Row>
    </Column>
  </Row>
  <slot v-else>Online content slot</slot>
</template>
