import { useValidation } from '@madxnl/dodo-ui'
import { ClientError } from 'graphql-request'
import { reactive, ref } from 'vue'
import { useAuthSession } from '.'

export function useLoginForm() {
  const { login } = useAuthSession()

  const email = ref('')
  const password = ref('')
  const loginError = ref('')
  const data = reactive({ email, password })

  const { errors, validate } = useValidation({
    email: { value: email, required: true, isEmail: true, maxLen: 50 },
    password: { value: password, required: true, maxLen: 30 },
  })

  async function submit() {
    loginError.value = ''
    const valid = await validate()
    if (!valid) return

    try {
      const success = await login(data)
      return success
    } catch (err) {
      if (err instanceof ClientError && err.message.includes('login credentials')) {
        loginError.value = 'Incorrect email or password'
        return
      }
      throw err
    }
  }

  return { data, submit, errors, loginError }
}
