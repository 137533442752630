<script lang="ts" setup>
import { InlineMessage, TwinIcon } from '@/ui/components'
import { Button, Column, Form, FormItem, TextInput } from '@madxnl/dodo-ui'
import { RouterLink } from 'vue-router'
import { useFogotPasswordForm } from '../composables'
import { authLinks } from '../routes'
import AuthPageLayout from './AuthPageLayout.vue'

const { data, errors, submit, submitMessage, submitted } = useFogotPasswordForm()
</script>

<template>
  <Form @submit="submit">
    <AuthPageLayout title="Forgot password">
      <template #back>
        <RouterLink v-slot="{ navigate }" :to="authLinks.login()">
          <Button variant="link" color="primary" @click="navigate">
            <TwinIcon icon="ChevronLeft" />
            Back
          </Button>
        </RouterLink>
      </template>

      <template v-if="submitMessage">
        <InlineMessage :color="submitMessage.code">
          {{ submitMessage.message }}
        </InlineMessage>
      </template>

      <Column v-if="submitted" gap="l">
        <p>Follow the instructions in the email to reset your password.</p>

        <RouterLink :to="authLinks.login()">
          <Button variant="solid" color="primary" style="width: 100%">Go back to log in</Button>
        </RouterLink>
      </Column>

      <Column v-else gap="l">
        <FormItem label="Email" :error="errors.email">
          <TextInput id="email" v-model="data.email" type="email" autocomplete="email" />
        </FormItem>
        <Button type="submit" variant="solid" color="primary">Request password reset</Button>
      </Column>
    </AuthPageLayout>
  </Form>
</template>
