<script setup lang="ts">
import { computed } from 'vue'
import { useEditorView } from '../composables'
import { Vec2 } from './vec2'

type EndPoint = { x: number; y: number; angle: number }
export type EndPoints = { start: EndPoint; end: EndPoint }

const props = defineProps<{
  points: EndPoints
  disabled?: boolean
  active?: boolean
}>()

const emit = defineEmits<{
  click: []
}>()

const strokeWidth = 3
const gridSize = 16
const hitboxWidth = 16

const { zoomScale, containerRect, viewPosition } = useEditorView()

const transformedPoints = computed(() => {
  const rect = containerRect.value
  const centerX = rect.left + rect.width / 2
  const centerY = rect.top + rect.height / 2
  const [viewX, viewY] = viewPosition.value
  const zoom = 1 / zoomScale.value
  const offsetX = viewX - zoom * centerX
  const offsetY = viewY - zoom * centerY
  let { x: x1, y: y1 } = props.points.start
  let { x: x2, y: y2 } = props.points.end
  x1 = zoom * x1 + offsetX
  y1 = zoom * y1 + offsetY
  x2 = zoom * x2 + offsetX
  y2 = zoom * y2 + offsetY
  const start = new Vec2(x1, y1)
  const end = new Vec2(x2, y2)
  return [start, end] as const
})

const pathPoints = computed(() => {
  const startAngle = props.points.start.angle
  const endAngle = props.points.end.angle
  // Generate the path points for the SVG line
  const [start, end] = transformedPoints.value
  const forwardDist = 32
  const forward = new Vec2(0, forwardDist)
  const startForward = forward.rotate(startAngle).add(start).round(1)
  const endForward = forward.rotate(endAngle).add(end).round(1)
  const fullPath = generatePath(start, startForward, endForward, end)
  return fullPath
})

function generatePath(a: Vec2, b: Vec2, c: Vec2, d: Vec2): Vec2[] {
  // To connect from the start to the end with 90 degree angles, we try different paths
  // First we make L shape path + mirrored, then shape shape + mirrored
  const lPath = [a, b, new Vec2(b.x, c.y), c, d]
  const lPathFlip = [a, b, new Vec2(c.x, b.y), c, d]
  const mid = b.add(c).scale(0.5).round(gridSize) // grid aligned center for the middle part of the Z paths
  const zPath = [a, b, new Vec2(mid.x, b.y), new Vec2(mid.x, c.y), c, d]
  const zPathFlip = [a, b, new Vec2(b.x, mid.y), new Vec2(c.x, mid.y), c, d]
  const candidates = [lPath, lPathFlip, zPath, zPathFlip]
  for (const path of candidates) removeDuplicatePoints(path)
  // The best path is the one with the fewest turns, i.e. the one with the smallest angle sum
  let best = { path: candidates[0]!, angleSum: Infinity }
  for (const path of candidates) {
    let angleSum = 0
    for (let i = 1; i < path.length - 1; i++) {
      const ab = path[i]!.subtract(path[i - 1]!)
      const bc = path[i + 1]!.subtract(path[i]!)
      const angle = ab.angleTo(bc)
      angleSum += angle * angle // punish sharp turns
    }
    if (angleSum < best.angleSum) best = { path, angleSum }
  }
  return best.path
}

function calcCornerArc(a: Vec2, b: Vec2, c: Vec2) {
  // Calculate the anchor points and radius for the corner arc between a, b, c
  // See https://observablehq.com/@carpiediem/svg-paths-with-circular-corners
  const rCorner = gridSize
  const ba = a.subtract(b)
  const bc = c.subtract(b)
  const theta = ba.angleTo(bc)
  if (theta === 0) return { arc1: b, arc2: b, radius: 0 }
  const dAnchor = rCorner / Math.abs(Math.tan(Math.abs(theta) / 2))
  const dMax = Math.min(ba.length(), bc.length()) / 2
  const dAnchorClamped = Math.min(dAnchor, dMax)
  const arc1 = ba.normalize().scale(dAnchorClamped).add(b)
  const arc2 = bc.normalize().scale(dAnchorClamped).add(b)
  const radius = dAnchorClamped
  return { arc1, arc2, radius } as const
}

function removeDuplicatePoints(path: Vec2[]) {
  // Clean up the path by rounding and removing duplicate points
  path.forEach((p, i) => (path[i] = p.round(1)))
  for (let i = path.length - 1; i > 1; i--) {
    if (path[i]!.equals(path[i - 1]!)) path.splice(i, 1)
  }
}

const bounds = computed(() => {
  // The bounding box of the svg line path, in editor coordinates
  const bounds = { left: 1e9, top: 1e9, right: -1e9, bottom: -1e9, width: 0, height: 0 }
  const margin = hitboxWidth
  for (const p of pathPoints.value) {
    bounds.left = Math.min(bounds.left, p.x - margin)
    bounds.top = Math.min(bounds.top, p.y - margin)
    bounds.right = Math.max(bounds.right, p.x + margin)
    bounds.bottom = Math.max(bounds.bottom, p.y + margin)
  }
  bounds.width = bounds.right - bounds.left
  bounds.height = bounds.bottom - bounds.top
  return bounds
})

const css = computed(() => {
  // CSS style for the SVG line position
  return {
    left: `${bounds.value.left}px`,
    top: `${bounds.value.top}px`,
    '--stroke-width': `${strokeWidth}px`,
    '--hitbox-width': `${hitboxWidth}px`,
  }
})

const svgPathString = computed(() => {
  // Generate the SVG path string from the points
  const { left, top } = bounds.value
  const localPoints = pathPoints.value.map((p) => new Vec2(p.x - left, p.y - top))
  const start = localPoints[0]!
  let path = `M ${start.x} ${start.y} `
  for (let i = 1; i < localPoints.length - 1; ++i) {
    const a = localPoints[i - 1]!
    const b = localPoints[i]!
    const c = localPoints[i + 1]!
    const determinant = (b.x - a.x) * (c.y - a.y) - (c.x - a.x) * (b.y - a.y)
    const sweep = determinant < 0 ? 0 : 1
    const { arc1, arc2, radius } = calcCornerArc(a, b, c)
    path += `L ${arc1.x} ${arc1.y} A ${radius} ${radius} 0 0 ${sweep} ${arc2.x} ${arc2.y}`
  }
  const end = localPoints[localPoints.length - 1]!
  path += `L ${end.x} ${end.y}`
  return path
})

async function clickLine(event: MouseEvent) {
  event.preventDefault()
  emit('click')
}
</script>

<template>
  <svg
    v-if="svgPathString"
    :width="bounds.width"
    :height="bounds.height"
    xmlns="http://www.w3.org/2000/svg"
    :class="[$style.lineSvg, disabled && $style.disabled, active && $style.active]"
    :style="css"
    @click="clickLine"
  >
    <path :d="svgPathString" :class="$style.hitbox" />
    <path :d="svgPathString" :class="$style.line" />
  </svg>
</template>

<style module>
.lineSvg {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  stroke-linecap: round;
  fill: none;
}
.lineSvg:hover {
  z-index: 1;
}
.line {
  pointer-events: none;
  stroke-width: var(--stroke-width);
  stroke: var(--grey-3-outlines);
  pointer-events: none;
}
.hitbox {
  pointer-events: stroke;
  stroke-width: var(--hitbox-width);
  cursor: pointer;
}
.disabled .hitbox {
  pointer-events: none;
}
.hitbox:hover + .line,
.active .line {
  stroke: var(--brand-color-1);
  cursor: pointer;
}
</style>
