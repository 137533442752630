import { useRouter } from 'vue-router'
import { authLinks } from '../routes'

export function useLoginRedirect() {
  const router = useRouter()
  const key = 'postLoginRedirect'

  function redirectToLogin() {
    sessionStorage.setItem(key, router.currentRoute.value.fullPath)
    return router.push(authLinks.login())
  }

  function postLoginRedirect() {
    const path = sessionStorage.getItem(key) ?? '/'
    sessionStorage.removeItem(key)
    return router.replace(path)
  }

  return {
    redirectToLogin,
    postLoginRedirect,
  }
}
