import { useValidation } from '@madxnl/dodo-ui'
import { Ref, computed, reactive, ref } from 'vue'
import { useApiClient } from '@/api'
import { useValidatePassword } from '.'

type Message = {
  code: 'info' | 'warning' | 'danger' | 'success'
  message: string
}

const ERROR_USER_NOT_FOUND = '#0306'

export function useSetPasswordForm() {
  const { client } = useApiClient()
  const { validatePassword } = useValidatePassword()

  const password = ref('')
  const data = reactive({ password, token: '', email: '' })
  const submitted = ref(false)
  const submitMessage: Ref<Message | undefined> = ref()

  const { errors, validate } = useValidation({
    password: {
      value: computed(() => data.password),
      required: true,
      minLen: 6,
      maxLen: 30,
      validators: [validatePassword],
    },
  })

  async function submit() {
    submitMessage.value = undefined
    const valid = await validate()
    if (!valid) return

    try {
      const { resetPassword } = await client.resetPassword(data)
      if (resetPassword) {
        submitMessage.value = {
          code: 'success',
          message: 'Password set successfully',
        }
        submitted.value = true
      }
      return resetPassword
    } catch (err) {
      if (err instanceof Error && err.message.includes(ERROR_USER_NOT_FOUND)) {
        submitMessage.value = {
          code: 'danger',
          message: 'Reset password token is invalid or expired',
        }
        return
      }
      throw err
    }
  }

  return { data, submit, errors, submitMessage, submitted }
}
