<script setup lang="ts">
import { TabularExportType } from '@/generated/sdk'
import { MainPageLayout } from '@/navigation/components'
import { useRunResults } from '@/runs/composables'
import { IconBullet, LargeModalLayout, TwinIcon } from '@/ui/components'
import { useEditorLinks, useWorkflowDetails } from '@/workflow-edit/composables'
import { type BlockOutput } from '@/workflow-edit/sidebar/useBlockOutput'
import { workflowLinks } from '@/workflows/routes'
import { Button, Column, Dropdown, FormItem, Row, Select } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import DataActionBar from './DataActionBar.vue'
import DataPageTable from './DataPageTable.vue'
import { useWorkflowData } from './useWorkflowData'

const router = useRouter()
const { linkReviewData } = useEditorLinks()

const { workflow } = useWorkflowDetails()
const { resetSelectedRuns, downloadResultsLatestRun, downloadResultsAllRuns } = useRunResults()
const {
  selectedBlockConfig,
  filterOptions,
  selectedBlockConfigId,
  blockOutput,
  isDataFromParentWorkflow,
  getDataName,
} = useWorkflowData()

const isFilterVisible = ref(false)

watch(selectedBlockConfigId, resetSelectedRuns, { immediate: true })

async function handleDownloadResults(type: TabularExportType, isLatest: boolean) {
  const blockConfigId = workflow.value?.blockConfigs[0]?.id
  if (!blockConfigId) return

  return isLatest
    ? await downloadResultsLatestRun(blockConfigId, type)
    : await downloadResultsAllRuns(workflow.value!.id, type)
}

async function handleOpenParentWorkflow(blockOutput: BlockOutput) {
  const parentWorkflow = blockOutput.parentWorkflow.value
  const workflowBlock = blockOutput.workflowBlock.value
  if (!parentWorkflow) return
  const route = workflowBlock
    ? linkReviewData(parentWorkflow.id, workflowBlock.id)
    : workflowLinks.workflowData(parentWorkflow.id)
  await router.push(route)
}
</script>

<template>
  <MainPageLayout full-screen>
    <template v-if="workflow">
      <Column gap="xl">
        <Column>
          <Row>
            <Row grow>
              <h1>Results of {{ workflow.name }}</h1>
            </Row>

            <Row>
              <Dropdown class="navbar-dropdown">
                <template #trigger="{ toggle }">
                  <Button @click="toggle"> Download </Button>
                </template>
                <template #content>
                  <Button
                    v-for="(type, index) in [TabularExportType.Excel, TabularExportType.Csv]"
                    :key="index"
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="handleDownloadResults(type, false)"
                  >
                    Download all ({{ type === TabularExportType.Excel ? '.xlsx' : '.csv' }})
                  </Button>
                  <Button
                    v-for="(type, index) in [TabularExportType.Excel, TabularExportType.Csv]"
                    :key="index + 2"
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="handleDownloadResults(type, true)"
                  >
                    Download latest ({{ type === TabularExportType.Excel ? '.xlsx' : '.csv' }})
                  </Button>
                </template>
              </Dropdown>
            </Row>
          </Row>

          <p>
            Here you can find the latest workflow results. Every line is an instance of the workflow, called a run.<br />
            Each run has a result, as shown in the first column of the table below. The input used to run the workflow
            is visible in the columns after the result column.
          </p>
        </Column>

        <Column>
          <Row justify="between">
            <h3>Workflow output & input data</h3>
            <Row gap="s">
              <Button @click="isFilterVisible = true">Filters</Button>
            </Row>
          </Row>
          <hr />

          <template v-if="selectedBlockConfigId">
            <template v-if="isDataFromParentWorkflow(selectedBlockConfigId)">
              <Row gap="l">
                <Row>
                  <IconBullet icon="Workflow" color="orange" />
                  <Button class="button-narrow" variant="link" @click="() => handleOpenParentWorkflow(blockOutput)">
                    <h4>{{ getDataName(selectedBlockConfig) }}</h4>
                    <TwinIcon color="primary" icon="LinkExternal" />
                  </Button>
                </Row>
              </Row>
            </template>
          </template>

          <DataPageTable
            v-if="selectedBlockConfig"
            :block-config="selectedBlockConfig"
            :block-output="blockOutput"
            :input-arguments="workflow.inputArguments"
          />
        </Column>
      </Column>
    </template>

    <template #footer>
      <DataActionBar />
    </template>
  </MainPageLayout>

  <LargeModalLayout :open="isFilterVisible" title="Filter runs" @close="isFilterVisible = false">
    <template #content>
      <Column gap="m">
        <FormItem label="Runs from...">
          <Select v-if="filterOptions" v-model="selectedBlockConfigId" :options="filterOptions" />
        </FormItem>
      </Column>
    </template>

    <template #footer="{ close }">
      <Button color="primary" variant="solid" @click="close()">Show results</Button>
    </template>
  </LargeModalLayout>
</template>

<style module>
.dropdownButton {
  justify-content: start;
}
</style>
