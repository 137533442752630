<script setup lang="ts">
import { BlockConfigDetailsFragment, WorkflowBlockItemFragment, WorkflowBlockType } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useUtils } from '@/ui/composables'
import { Button, Card, Chip, Column, Row } from '@madxnl/dodo-ui'
import { ComponentInstance, computed, ref } from 'vue'
import { RouterLink } from 'vue-router'
import { useEditorLinks, useWorkflowDetails, useWorkflowEditor } from '../composables'
import EditorBlockConnector from './EditorBlockConnector.vue'
import EditorBlockIcon from './EditorBlockIcon.vue'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment | null
  blockConfig: BlockConfigDetailsFragment
  selected?: boolean
  disabled: boolean
  disableHover?: boolean
  disableOutput?: boolean
  isDataReview?: boolean
}>()

const activateConnectors = ref(false)

const { getNextBlocks, getBlockTypeDetails, getBlockOutputConditions } = useWorkflowEditor()
const { workflow } = useWorkflowDetails()
const { linkSubworkflowEdit } = useEditorLinks()
const { truncate } = useUtils()

const blockType = computed(() => getBlockTypeDetails(props.blockConfig.block))
const hasName = computed(() => !!props.workflowBlock?.name)

const isSwitch = computed(() => props.workflowBlock?.blockType === WorkflowBlockType.Switch)
const isIfElse = computed(() => props.workflowBlock?.blockType === WorkflowBlockType.IfElse)
const isNormal = computed(() => !isSwitch.value && !isIfElse.value)

const nextBlocks = computed(() => (props.workflowBlock ? getNextBlocks(props.workflowBlock) : []))
const outputConditions = computed(() => (props.workflowBlock ? getBlockOutputConditions(props.workflowBlock) : []))

const subworkflowLink = computed(() => {
  if (!workflow.value || !props.blockConfig.workflow) return
  return linkSubworkflowEdit(workflow.value.id, props.blockConfig.workflow.id)
})

type ConnectorInstance = ComponentInstance<typeof EditorBlockConnector>

// We need to expose connector elements so we can draw lines to them
const inputConnector = ref<ConnectorInstance>()
const outputConnector = ref<ConnectorInstance>()
const yesConnector = ref<ConnectorInstance>()
const noConnector = ref<ConnectorInstance>()
const switchConnectors = ref<ConnectorInstance[]>([])

const outputConnectors = computed(() =>
  [outputConnector.value!, ...switchConnectors.value, yesConnector.value!, noConnector.value!].filter(Boolean),
)

defineExpose({ outputConnectors, inputConnector })
</script>

<template>
  <Card
    gap="0"
    padding="0"
    :active="selected"
    :class="[$style.card, isSwitch && $style.wideCard]"
    :hoverable="!disableHover"
    @mouseover="activateConnectors = true"
    @mouseleave="activateConnectors = false"
  >
    <div :class="$style.input">
      <EditorBlockConnector
        ref="inputConnector"
        :disabled="disabled"
        :workflow-block="workflowBlock"
        :is-data-review="isDataReview"
        :hover-state="activateConnectors"
        is-input
      />
    </div>

    <div :class="$style.switchLayout">
      <div :class="$style.titleGrid">
        <EditorBlockIcon :block="blockConfig.block" />
        <h4 class="overflow-ellipsis">
          {{ truncate(workflowBlock?.name || blockType.readableName, 38) }}
        </h4>

        <Column v-if="hasName || subworkflowLink" :class="$style.description" gap="s">
          <p v-if="hasName" class="form-description">{{ blockType?.readableName }}</p>

          <RouterLink v-if="subworkflowLink" v-slot="{ navigate }" :to="subworkflowLink">
            <Button variant="link" color="primary" @click="navigate">
              Open
              <TwinIcon icon="LinkExternal" />
            </Button>
          </RouterLink>
        </Column>
      </div>

      <div v-if="isSwitch" :class="$style.switch">
        <Row v-for="condition in outputConditions" :key="condition || 'output'" justify="end" gap="m">
          <Row padding="s">
            <p>{{ JSON.stringify(condition) }}</p>
          </Row>
          <EditorBlockConnector
            ref="switchConnectors"
            :condition="condition || undefined"
            :disabled="disabled"
            :workflow-block="workflowBlock"
            is-switch
            :is-data-review="isDataReview"
            :hover-state="activateConnectors"
          />
        </Row>
      </div>
    </div>

    <div v-if="isIfElse" :class="$style.yesNo">
      <div :class="$style.yesNoCol">
        <Chip color="success" :class="[nextBlocks?.every((x) => x?.condition !== 'true') && $style.chipDisabled]">
          Yes
        </Chip>

        <div :class="$style.yesNoLine"></div>

        <EditorBlockConnector
          ref="yesConnector"
          condition="true"
          :disabled="disabled"
          :workflow-block="workflowBlock"
          :is-data-review="isDataReview"
          :hover-state="activateConnectors"
        />
      </div>
      <div :class="$style.yesNoCol">
        <Chip color="danger" :class="[nextBlocks?.every((x) => x?.condition !== 'false') && $style.chipDisabled]">
          No
        </Chip>

        <div :class="$style.yesNoLine"></div>

        <EditorBlockConnector
          ref="noConnector"
          condition="false"
          :disabled="disabled"
          :workflow-block="workflowBlock"
          :is-data-review="isDataReview"
          :hover-state="activateConnectors"
        />
      </div>
    </div>

    <div v-if="isNormal" :class="$style.output">
      <EditorBlockConnector
        ref="outputConnector"
        :disabled="disabled || !!disableOutput"
        :workflow-block="workflowBlock"
        :is-data-review="isDataReview"
        :hover-state="activateConnectors"
      />
    </div>

    <!-- <p v-if="validationMessages.length > 0" :class="[$style.blockError]">
    <template v-for="m in validationMessages" :key="m.message">{{ m.message }}<br /></template>
    </p> -->
  </Card>
</template>

<style module>
.card {
  width: 256px;
  -webkit-user-select: none;
  user-select: none;
}
.wideCard {
  width: 512px;
}
.switchLayout {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
.switch {
  border-left: 1px solid var(--grey-2-lines);
}
.switch > :not(:last-child) {
  border-bottom: 1px solid var(--grey-2-lines);
}
.titleGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
  padding: 16px;
}
.description {
  grid-column: 2;
}
.chipDisabled {
  opacity: 0.5;
}
.blockError {
  font-size: var(--dodo-font-small);
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--dodo-color-danger-light);
  color: var(--dodo-color-danger);
}
.yesNo {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}
.yesNoCol {
  position: relative;
  display: grid;
  place-items: center;
}
.yesNoLine {
  content: '';
  display: block;
  width: 3px;
  height: 16px;
  background: rgba(0, 0, 0, 0.2);
}
.output,
.input {
  display: flex;
  justify-content: center;
}
</style>
