<script setup lang="ts">
import { onMounted } from 'vue'
import { useOrganizationForm } from '../composables'
import { Button, Row, FormItem, TextInput } from '@madxnl/dodo-ui'
import { AddressFields } from '.'

const { errors, load, data, submit, saved, address, billingAddress, billingSameAddress } = useOrganizationForm()

onMounted(load)
</script>

<template>
  <FormItem label="Organization name" :error="errors.name">
    <TextInput v-model="data.name" />
  </FormItem>

  <FormItem label="Email address" :error="errors.email">
    <TextInput v-model="data.email" name="email" />
  </FormItem>

  <FormItem label="Phone number" :error="errors.phoneNumber">
    <TextInput v-model="data.phoneNumber" />
  </FormItem>

  <AddressFields v-model:address="address.data" :errors="address.errors" />

  <h4>Billing address</h4>

  <FormItem label="Same as organization address">
    <Row><input v-model="billingSameAddress" type="checkbox" /></Row>
  </FormItem>
  <template v-if="!billingSameAddress">
    <AddressFields v-model:address="billingAddress.data" :errors="billingAddress.errors" />
  </template>

  <Row>
    <Button color="primary" variant="solid" @click="submit">Save changes</Button>

    <p v-if="saved" class="dodo-color-success">Changes saved successfully.</p>
  </Row>
</template>
