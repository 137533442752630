<script setup lang="ts">
import { DisplayCurrency, TwinIcon } from '@/ui/components'
import { Button, Column, Datatable, Row, Spinner, TextInput } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { useUserOrganization } from '../composables'

const { organization, fetch } = useUserOrganization()

onMounted(fetch)
</script>

<template>
  <Column gap="l">
    <Row>
      <Button disabled><TwinIcon icon="Plus" />Invite</Button>
      <TextInput placeholder="Search" disabled />
    </Row>

    <Datatable
      v-if="organization"
      :class="$style.datatable"
      :rows="organization.organizations"
      :columns="[
        { name: 'Organisation', sort: 'name', value: 'name' },
        { name: 'Email', value: 'email' },
        { name: 'Plan', value: () => '—' },
        { name: 'AI budget', slot: 'budget' },
        { name: 'Workflows', value: (row) => row.workflows.length },
        { name: '', slot: 'actions', align: 'end' },
      ]"
    >
      <template #budget="{ row: organisation }">
        <DisplayCurrency :amount="organisation.subscriptionBudget" />
      </template>
      <template #actions>
        <Button square variant="link" disabled><TwinIcon icon="Eye" /></Button>
      </template>
    </Datatable>
    <Spinner v-else />
  </Column>
</template>

<style module>
.datatable {
  background-color: transparent;
}
</style>
