export function useValidatePassword() {
  function validatePassword(pw: string) {
    const uppercase = /[A-Z]/.test(pw)
    const lowercase = /[a-z]/.test(pw)
    const number = /[0-9]/.test(pw)
    const special = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(pw)
    if (!uppercase) return 'Password must contain at least one uppercase letter'
    if (!lowercase) return 'Password must contain at least one lowercase letter'
    if (!number) return 'Password must contain at least one number'
    if (!special) return 'Password must contain at least one special character'
    return undefined
  }

  return {
    validatePassword,
  }
}
