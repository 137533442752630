<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  date: Date | number // Date or timestamp
}>()

// This component renders a date, possible improvements: Hover text with full date, relative date, etc.

const dateString = computed(() => {
  const date = new Date(props.date)
  if (!props.date || isNaN(date.getTime())) return '-'
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })
})
</script>

<template>
  <span class="dodo-nowrap">{{ dateString }}</span>
</template>
