<script lang="ts" setup>
import { Button, Column, Row } from '@madxnl/dodo-ui'
import AuthPageLayout from './AuthPageLayout.vue'
import { TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { useApiClient } from '@/api'
import { useRoute } from 'vue-router'

const { client } = useApiClient()
const currentRoute = useRoute()
const { email, source } = currentRoute.query
const { showMessage } = useSimpleMessage()

const title = source === 'login' ? 'Your account is not activated yet' : 'Your Twin AI account has been created'

async function clickResend() {
  if (typeof email === 'string') {
    await client.resendVerificationEmail({ email })
    showMessage("We've send an email, check your inbox")
  }
}
</script>

<template>
  <AuthPageLayout :title="title" full-width>
    <Column>
      <Row>
        <TwinIcon icon="Email" />
        <h4>Verify your email address</h4>
      </Row>

      <Row>
        <p>
          We’ve sent an email to <u>{{ email }}</u>
          <br />
          Click on the confirmation link in the email to access your Twin AI account.
        </p>
      </Row>
    </Column>

    <Row>
      <Button @click="clickResend"><TwinIcon icon="Undo" />Resend email</Button>
    </Row>
  </AuthPageLayout>
</template>
