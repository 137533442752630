<script setup lang="ts">
import { BlockItemFragment } from '@/generated/sdk'
import { useDraggable, useUtils } from '@/ui/composables'
import { Card, Column, Row } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import EditorBlockIcon from '../block/EditorBlockIcon.vue'
import { useBlockTypes, useEditorView, useWorkflowEditor } from '../composables'

const { blockTypeCategories } = useBlockTypes()
const { addNewBlock } = useWorkflowEditor()
const { screenToViewCoords, containerRect } = useEditorView()
const { asyncContext } = useUtils()

const props = defineProps<{
  disabled: boolean
}>()

const itemBeingDragged = ref<BlockItemFragment>()
const itemDimensions = { width: 320 - 32, height: 64 }

const dragPosStyle = computed(() => {
  return {
    left: drag.position.value.x + 'px',
    top: drag.position.value.y + 'px',
    width: itemDimensions.width + 'px',
    height: itemDimensions.height + 'px',
  }
})

const drag = useDraggable({
  drag(dx, dy) {},
  end(ev) {
    const item = itemBeingDragged.value!
    itemBeingDragged.value = undefined

    const { x, y } = drag.position.value
    const { left, top, height, width } = containerRect.value
    const insideContainerRect = x >= left && x <= left + width && y >= top && y <= top + height
    if (!insideContainerRect) return // cancel

    const [vx, vy] = screenToViewCoords([x, y])
    const dims = itemDimensions
    const pos: [number, number] = [vx - dims.width / 2, vy - dims.height / 2]
    const blockType = item.workflowBlockType
    const blockConfigBlock = item.id

    asyncContext(async () => {
      await addNewBlock({ pos, blockType, blockConfigBlock }, { select: true })
    })
  },
})

function startDrag(event: PointerEvent, blockType: BlockItemFragment) {
  if (props.disabled) return
  itemBeingDragged.value = blockType
  drag.startDrag(event)
}
</script>

<template>
  <Column gap="l">
    <Column v-for="category of blockTypeCategories" :key="category.name">
      <h4>{{ category.name }}</h4>

      <Card
        v-for="b of category.blockTypes"
        :key="b.id"
        :class="[$style.blockTypeBtn, disabled && $style.disabled]"
        hoverable
        @pointerdown.left="startDrag($event, b)"
      >
        <Row>
          <EditorBlockIcon :block="b.id" />
          <h4>{{ b.readableName }}</h4>
        </Row>
      </Card>
    </Column>
  </Column>

  <Teleport to="body">
    <Card v-if="itemBeingDragged" :class="$style.dragging" :style="dragPosStyle">
      <Row>
        <EditorBlockIcon :block="itemBeingDragged.id" />
        <h4>{{ itemBeingDragged.readableName }}</h4>
      </Row>
    </Card>
  </Teleport>
</template>

<style module>
.blockTypeBtn {
  cursor: grab;
  user-select: none;
}
.dragging {
  box-shadow: var(--card-shadow-hover);
  position: fixed;
  overflow: hidden;
  z-index: 100;
  cursor: grabbing;
  outline: 2px solid var(--dodo-color-primary);
  transform: translate(-50%, -50%);
  transition: none;
}
.disabled {
  cursor: default;
  opacity: 0.5;
}
</style>
