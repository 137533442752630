<script setup lang="ts">
import { TwinIcon } from '@/ui/components'
import { Button, Row } from '@madxnl/dodo-ui'
import { useEditorView } from './composables'

const { zoomScale, zoomInOut } = useEditorView()
</script>

<template>
  <div :class="$style.controls">
    <Row padding="xs" gap="s">
      <Button size="s" square variant="link" :class="$style.roundBtn" @click="zoomInOut(false)">
        <TwinIcon icon="Minus" size="s" />
      </Button>

      <small :class="$style.zoomLabel">{{ Math.round(zoomScale * 100) }}%</small>

      <Button size="s" square variant="link" :class="$style.roundBtn" @click="zoomInOut(true)">
        <TwinIcon icon="Plus" size="s" />
      </Button>
    </Row>
  </div>
</template>

<style module>
.controls {
  position: absolute;
  background: var(--dodo-color-background);
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  box-shadow: var(--popup-shadow);
  border-radius: 32px;
}
.roundBtn {
  border-radius: 32px;
}
.zoomLabel {
  min-width: 40px;
  text-align: center;
}
</style>
