import { nextTick, ref } from 'vue'

export function useCaretPosition() {
  const caretPosition = ref<number | null>(null)

  function getCaretPosition(container: Element) {
    // Get the caret position in the text
    const selection = document.getSelection()
    if (!selection || !container) return null
    const { focusNode, focusOffset } = selection
    if (!container.contains(focusNode)) return null
    let position = 0
    const childNodes = [...container.childNodes] as (Text | Element)[]
    for (const child of childNodes) {
      if (child.contains(focusNode)) {
        position += focusOffset
        break
      }
      position += getNodeTextLength(child)
    }
    caretPosition.value = position
    return position
  }

  async function applyCaretPosition(container: Element) {
    // Check if container has focus inside
    if (!container.contains(document.activeElement)) return
    // Apply the caret position to the container
    await nextTick()
    let position = caretPosition.value
    if (position == null || !container) return
    let offset = 0
    const childNodes = [...container.childNodes] as (Text | Element)[]
    const lastNode = childNodes[childNodes.length - 1]
    let node: Node = lastNode!
    for (const child of childNodes) {
      const length = getNodeTextLength(child)
      if (child === lastNode || position < length) {
        const prefixLen = isVar(child) ? '{{'.length : 0
        node = child.childNodes[0] ?? child
        offset = position - prefixLen
        break
      }
      position -= length
    }
    if (!node) return
    const finalOffset = Math.max(0, Math.min(offset, node.textContent?.length ?? 0))
    document.getSelection()?.setPosition(node, finalOffset)
  }

  function getNodeTextLength(node: Text | Element) {
    const textLength = node.textContent?.length ?? 0
    return isVar(node) ? textLength + '{{}}'.length : textLength
  }

  function isVar(node: Text | Element) {
    // return 'classList' in node && node.classList.contains(css.var)
    return false
  }

  return { caretPosition, getCaretPosition, applyCaretPosition }
}
