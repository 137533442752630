import { RequireAuth } from '@/auth/components'
import { Plugin } from 'vue'
import { Router } from 'vue-router'
import { Navigation } from '../navigation'
import { RunOverviewPage, RunsNavItem } from './components'
import { runRoutes, runLinks } from './routes'

export const runsPlugin: Plugin<{ router: Router; navigation: Navigation }> = {
  install: (_, options) => {
    // Register run routes under a parent route with auth guard
    options.router.addRoute({
      name: 'runs',
      path: '/runs',
      component: RequireAuth,
      children: [{ ...runRoutes.runs, component: RunOverviewPage }],
      redirect: runLinks.runs(),
    })

    // Add run link to navigation
    options.navigation.navEntries.addNavEntry({
      component: RunsNavItem,
      position: 'end',
      order: 0,
    })
  },
}
