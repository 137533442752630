import { useApiClient } from '@/api'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref, toRef } from 'vue'
import { useAddressForm } from '.'
import { useCurrentUser } from '@/auth/composables'
import { OrganizationDetailsFragment } from '@/generated/sdk'

const name = ref('')
const email = ref('')
const phoneNumber = ref('')
const data = reactive({ name, email, phoneNumber })
const saved = ref(false)
const billingSameAddress = ref(true)

const address = useAddressForm()
const billingAddress = useAddressForm()

export function useOrganizationForm() {
  const { client } = useApiClient()
  const { currentUser } = useCurrentUser()

  const { errors, validate } = useValidation({
    name: { value: name, required: true },
    email: { value: email },
    phoneNumber: { value: phoneNumber },
  })

  const organizationId = toRef(() => {
    return currentUser.value?.organization?.id
  })

  function setData(organization: OrganizationDetailsFragment) {
    Object.assign(data, organization)
    address.loadData(organization.address ?? null)
    billingAddress.loadData(organization.billingAddress ?? null)
    billingSameAddress.value = !organization.billingAddress
  }

  async function load() {
    if (!organizationId.value) throw new Error('User has no organization')
    const res = await client.organization({ id: organizationId.value })
    const organisation = res.organization[0]
    if (!organisation) throw new Error('Organization not found')
    setData(organisation)
  }

  async function submit() {
    saved.value = false
    const isValid = await validate()
    const addressValid = await address.validate()
    if (!isValid || !addressValid) return

    if (!billingSameAddress.value) {
      const billingAddressValid = await billingAddress.validate()
      if (!billingAddressValid) return
    }

    const dataWithAddress = {
      name: data.name,
      phoneNumber: data.phoneNumber,
      email: data.email,
      address: { ...address.data },
      billingAddress: billingSameAddress.value ? null : { ...billingAddress.data },
      id: organizationId.value,
    }
    const result = await client.updateOrganization({ data: dataWithAddress })
    setData(result.updateOrganization)
    saved.value = true
  }

  return { data, address, load, submit, errors, saved, billingAddress, billingSameAddress }
}
