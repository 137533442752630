import { useApiClient } from '@/api'

export function useValidateEmail() {
  const { client } = useApiClient()

  async function validateEmail(email: string) {
    const { isEmailAvailable } = await client.isEmailAvailable({ email })
    if (!isEmailAvailable) return 'This email address is already registered.'
    return undefined
  }

  return {
    validateEmail,
  }
}
