<script lang="ts" setup>
import { useApiClient } from '@/api'
import { computed, onBeforeUnmount, ref } from 'vue'

const { pendingCalls } = useApiClient()

const progress = ref(0)
const animationRequest = ref(requestAnimationFrame(update))
const isLoading = computed(() => pendingCalls.value.length > 0)

function calculateProgress() {
  const lastCall = pendingCalls.value[pendingCalls.value.length - 1]
  if (!lastCall) return 1
  const elapsed = Date.now() - lastCall.startedAt
  const speed = 0.001
  // Value transitions from 0 to 1 as time goes on
  // return 1 - 1 / Math.pow(1 + elapsed, speed)
  return 1 - 1 / Math.pow(1 + speed, elapsed)
}

function update() {
  progress.value = calculateProgress()
  animationRequest.value = requestAnimationFrame(update)
}

onBeforeUnmount(() => {
  cancelAnimationFrame(animationRequest.value)
})
</script>

<template>
  <div :class="$style.bar">
    <Transition
      appear
      :enter-from-class="$style.enter"
      :leave-to-class="$style.enter"
      :leave-active-class="$style.leaving"
    >
      <div v-if="isLoading" :style="`--progress:${Math.floor(progress * 100)}%`" :class="$style.progress"></div>
    </Transition>
  </div>
</template>

<style module>
.bar {
  margin-bottom: -3px;
  z-index: 3;
  height: 3px;
  flex-shrink: 0;
  width: 100%;
}
.progress {
  transition: opacity 0.2s;
  background: var(--dodo-color-primary);
  width: var(--progress);
  height: 100%;
}
.leaving {
  width: 100%;
}
.enter {
  opacity: 0;
}
</style>
