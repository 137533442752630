import { computed, ref } from 'vue'
import { useUtils } from './useUtils'

// useDraggable is a composable function that implements the logic for dragging
export function useDraggable(args: {
  drag: (dx: number, dy: number, e: PointerEvent) => Promise<void> | void
  end?: (e: PointerEvent) => Promise<void> | void
}) {
  const startPosition = ref({ x: 0, y: 0 })
  const position = ref({ x: 0, y: 0 })
  const isDragging = ref(false)
  const { asyncContext } = useUtils()

  const displacement = computed(() => {
    const p = position.value
    const p0 = startPosition.value
    return { x: p.x - p0.x, y: p.y - p0.y }
  })

  const distance = computed(() => {
    const d = displacement.value
    return Math.hypot(d.x, d.y)
  })

  function startDrag(event: PointerEvent) {
    // Trigger start dragging the item
    position.value = { x: event.clientX, y: event.clientY }
    startPosition.value = { x: event.clientX, y: event.clientY }
    isDragging.value = true

    document.addEventListener('pointermove', pointermove)
    document.addEventListener('pointerup', pointerup)

    event.preventDefault()
  }

  function pointermove(event: PointerEvent) {
    // Update the position of the item being dragged
    const change = { x: event.clientX - position.value.x, y: event.clientY - position.value.y }
    position.value = { x: event.clientX, y: event.clientY }
    asyncContext(() => args.drag(change.x, change.y, event))
  }

  function pointerup(event: PointerEvent) {
    asyncContext(() => args.end?.(event))
    cancel()
    event.preventDefault()
  }

  function cancel() {
    isDragging.value = false
    startPosition.value = { x: 0, y: 0 }
    position.value = { x: 0, y: 0 }
    document.removeEventListener('pointermove', pointermove)
    document.removeEventListener('pointerup', pointerup)
  }

  return { startDrag, position, distance, isDragging, startPosition, displacement, cancel }
}
