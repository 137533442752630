<script setup lang="ts">
import { Button, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { usePasswordChangeForm } from '../composables'

const { errors, data, submit, reset, saved } = usePasswordChangeForm()

onMounted(reset)
</script>

<template>
  <input type="text" style="position: absolute; right: 9999px" />

  <FormItem label="Old Password" :error="errors.oldPassword">
    <TextInput v-model="data.oldPassword" type="password" />
  </FormItem>

  <FormItem label="New Password" :error="errors.newPassword">
    <TextInput v-model="data.newPassword" type="password" />
  </FormItem>

  <Row>
    <Button color="primary" variant="solid" @click="submit">Change Password</Button>
    <p v-if="saved" class="dodo-color-success">Password changed successfully.</p>
  </Row>
</template>
