import { ref } from 'vue'
import { useEditorState } from './useEditorState'
import { useWorkflowEditor } from './useWorkflowEditor'

const containerRect = ref({ left: 0, top: 0, width: 0, height: 0 })

export function useEditorView() {
  // Manipulation of the editor view eg zooming and panning

  const { zoomScale, viewPosition } = useEditorState()
  const { editorBlocks } = useWorkflowEditor()

  function centerView() {
    const rect = containerRect.value
    const min = { x: 1e9, y: 1e9 }
    const max = { x: -1e9, y: -1e9 }
    for (const block of editorBlocks.value) {
      const { width, height } = block.size()
      const [px, py] = block.position
      if (px < min.x) min.x = px
      if (py < min.y) min.y = py
      if (px + width > max.x) max.x = px + width
      if (py + height > max.y) max.y = py + height
    }
    const cx = min.x + (max.x - min.x) / 2
    const cy = min.y + (max.y - min.y) / 2
    const width = max.x - min.x
    const height = max.y - min.y
    const margin = 0.9 // 10% margin
    const newZoom = Math.min((rect.width * margin) / width, (rect.height * margin) / height)
    const maxInitialZoom = 1 // Don't zoom beyond 100% initially
    zoomScale.value = Math.min(maxInitialZoom, newZoom)
    viewPosition.value = [cx, cy]
  }

  function adjustZoom(newZoom: number, center: [number, number] | null) {
    const oldZoom = zoomScale.value
    const rect = containerRect.value
    const centerX = center ? center[0] : rect.left + rect.width / 2
    const centerY = center ? center[1] : rect.top + rect.height / 2
    const offsetX = centerX - (rect.left + rect.width / 2)
    const offsetY = centerY - (rect.top + rect.height / 2)
    const [oldX, oldY] = viewPosition.value
    const newX = oldX + offsetX / oldZoom - offsetX / newZoom
    const newY = oldY + offsetY / oldZoom - offsetY / newZoom
    zoomScale.value = newZoom
    viewPosition.value = [newX, newY]
  }

  function screenToViewCoords([x, y]: [number, number]): [number, number] {
    const scale = zoomScale.value
    const rect = containerRect.value
    const [vx, vy] = viewPosition.value
    const normX = x - (rect.left + rect.width / 2)
    const normY = y - (rect.top + rect.height / 2)
    return [vx + normX / scale, vy + normY / scale]
  }

  function panView(dx: number, dy: number) {
    const [x, y] = viewPosition.value
    const z = zoomScale.value
    viewPosition.value = [x + dx / z, y + dy / z]
  }

  const discreteLevels = [0.33, 0.5, 0.75, 1, 1.5, 2]

  function handleWheelEvent(e: WheelEvent) {
    // Adjust the zoom level
    const isPinchZoom = e.ctrlKey && !Number.isInteger(e.deltaY)
    let multiplier = Math.exp(e.deltaY * -0.001)
    if (isPinchZoom) {
      multiplier = Math.exp(e.deltaY * -0.02)
    }
    // Zoom towards mouse cursor
    const oldZoom = zoomScale.value
    const [zoomMin, zoomMax] = [discreteLevels[0]!, discreteLevels[discreteLevels.length - 1]!]
    const newZoom = Math.max(zoomMin, Math.min(oldZoom * multiplier, zoomMax))
    adjustZoom(newZoom, [e.clientX, e.clientY])
  }

  function zoomInOut(isZoomIn: boolean) {
    const current = zoomScale.value
    const nextLarger = discreteLevels.find((level) => level > current)
    const reverseLevels = discreteLevels.slice().reverse()
    const nextSmaller = reverseLevels.find((level) => level < current)
    const newZoom = isZoomIn ? nextLarger : nextSmaller
    if (!newZoom) return
    adjustZoom(newZoom, null)
  }

  return {
    screenToViewCoords,
    viewPosition,
    containerRect,
    panView,
    centerView,
    handleWheelEvent,
    zoomScale,
    zoomInOut,
  }
}
