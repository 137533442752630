<script setup lang="ts">
import { RunItemFragment } from '@/generated/sdk'
import { DisplayDate, RunStatus, TwinIcon } from '@/ui/components'
import { Button, Datatable, Row } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'

const props = defineProps<{
  runs: RunItemFragment[]
  workflow?: string
}>()

defineEmits<{
  clickDelete: [id: string]
}>()

const columns = computed(() => {
  const workflow = { name: 'Workflow', slot: 'workflow' }
  const created = {
    name: 'Created',
    sort: 'createdAt',
    slot: 'created',
  }
  const status = { name: 'Status', slot: 'status' }
  const actions = { name: 'Actions', slot: 'actions', align: 'end', sort: '' }

  const columns = [workflow, created, status]
  if (!props.workflow) columns.push(actions)

  return columns
})

const sortedBy = ref<string>()

async function clickRedo(run: RunItemFragment) {
  // const resultDataset = await runWorkflow({ workflowId: run.workflow.id });
  // await router.push(datasetLinks.datasetDetail(resultDataset.id));
}
</script>

<template>
  <Datatable :rows="runs" :columns="columns" :sort-value="sortedBy">
    <template #workflow="{ row: run }">
      <Row>
        <strong>{{ run.blockConfig.workflow?.name ?? 'Unknown workflow' }}</strong>
        <span v-if="run.parentRun" class="dodo-fade-secondary">(subworkflow)</span>
      </Row>
    </template>

    <template #created="{ row: run }">
      <DisplayDate :date="parseInt(run.createdAt)" />
    </template>

    <template #status="{ row: run }">
      <RunStatus :run-status="run.status" />
    </template>

    <template #actions="{ row: run }">
      <Row justify="end">
        <Button color="warning" variant="solid" square aria-label="Redo run" disabled @click="clickRedo(run)">
          <TwinIcon icon="Redo" />
        </Button>
        <Button color="danger" variant="solid" square aria-label="Delete run" @click="$emit('clickDelete', run.id)">
          <TwinIcon icon="Delete" size="s" />
        </Button>
      </Row>
    </template>
  </Datatable>
</template>
