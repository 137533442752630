import { useApiClient } from '@/api'
import { TabularExportType } from '@/generated/sdk'
import { useBlockOutput } from '@/workflow-edit'
import { computed, ref } from 'vue'

const selectedRuns = ref<{ workflowId: string; runIds: string[] }[]>([])
const selectedRunIds = computed(() => selectedRuns.value.flatMap((run) => run.runIds))

export function useRunResults() {
  const api = useApiClient()
  const { runs, fetchOutput } = useBlockOutput()

  function toggleRun(workflowId: string, runId: string) {
    const group = selectedRuns.value.find((x) => x.workflowId === workflowId)
    if (selectedRunIds.value.includes(runId)) {
      if (!group) return
      const index = group.runIds.indexOf(runId)
      group.runIds.splice(index, 1)
      return
    }
    if (!group) selectedRuns.value.push({ workflowId, runIds: [runId] })
    else group.runIds.push(runId)
  }

  function resetSelectedRuns() {
    selectedRuns.value = []
  }

  async function deleteSelectedRuns() {
    const promises = selectedRunIds.value.flatMap((runId) => api.client.deleteRun({ runId }))
    await Promise.all(promises)
    resetSelectedRuns()
  }

  async function downloadResultsAllRuns(workflowId: string, type: TabularExportType) {
    const response = await api.client.downloadRunsByWorkflowId({ workflowId, type })
    await api.downloadFile(response.downloadRunsByWorkflowId)
  }

  async function downloadResultsLatestRun(blockConfigId: string, type: TabularExportType) {
    await fetchOutput(blockConfigId)
    const runId = runs.value?.[0]?.id
    if (!runId) return

    const response = await api.client.downloadRunByRunIds({ runIds: [runId], type })
    await api.downloadFile(response.downloadRunByRunIds)
  }

  async function downloadSelectedRuns(type: TabularExportType) {
    const downloadPromises = selectedRuns.value.flatMap(({ runIds }) => {
      return api.client.downloadRunByRunIds({ runIds, type })
    })
    const responses = await Promise.all(downloadPromises)
    const filePromises = responses.flatMap((response) => api.downloadFile(response.downloadRunByRunIds))
    await Promise.all(filePromises)
    resetSelectedRuns()
  }

  return {
    selectedRuns,
    selectedRunIds,
    toggleRun,
    resetSelectedRuns,
    deleteSelectedRuns,
    downloadResultsAllRuns,
    downloadResultsLatestRun,
    downloadSelectedRuns,
  }
}
