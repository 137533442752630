<script lang="ts" setup>
import { useCurrentUser } from '@/auth/composables'
import { NavbarItem } from '@/navigation/components'
import { TwinIcon } from '@/ui/components'
import { RouterLink } from 'vue-router'
import { userLinks } from '../routes'

const { displayName } = useCurrentUser()
</script>

<template>
  <RouterLink v-slot="{ isActive }" :to="userLinks.userSetting()">
    <NavbarItem :is-active="isActive">
      <TwinIcon icon="Account" />
      <span>{{ displayName(12) }}</span>
    </NavbarItem>
  </RouterLink>
</template>
