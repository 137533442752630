import { BlockConfigArgumentFragment } from '@/generated/sdk'

// Handlebars Template Abstract Syntax Tree Generation

export function useTemplateAST(
  css: { var: string; noarg: string },
  getArgByName: (name: string) => BlockConfigArgumentFragment | null,
) {
  function parseTemplateText(templateText: string) {
    // Convert text to html with handlebars highlighted
    return parseAST(templateText)
  }

  function parseAST(templateText: string) {
    let html = ''
    let textToProccess = templateText
    if (!textToProccess.length) return html
    const handlebarRegex = /{{([^{}]+)}}/
    const handlebarFound = textToProccess.match(handlebarRegex)
    const textBefore = handlebarFound ? textToProccess.slice(0, handlebarFound.index) : textToProccess
    if (textBefore) {
      html += textBefore
    }
    if (handlebarFound) {
      const handlebarString = handlebarFound[1]!
      const handlebarWords = handlebarString.trim().split(/\s+/)
      const name = handlebarWords[handlebarWords.length - 1]
      const blockArg = name ? getArgByName(name) : null
      const noarg = blockArg ? '' : `data-noarg`
      html += `<span data-var ${noarg}>{{${handlebarString}}}</span>`

      const indexAfter = handlebarFound.index! + handlebarFound[0].length
      textToProccess = textToProccess.slice(indexAfter)
      if (textToProccess[0] !== ' ') textToProccess = ' ' + textToProccess
      html += parseAST(textToProccess)
    }
    return html
  }

  function elementsToText(container: Element) {
    return toText(container)
  }

  function toText(el: ChildNode) {
    let newText = ''
    if (el.nodeType === Node.TEXT_NODE) {
      return el.textContent ?? ''
    } else if (el.nodeType === Node.ELEMENT_NODE) {
      // const dataset = (el as HTMLElement).dataset
      // const isVar = 'var' in dataset
      for (const child of el.childNodes) {
        newText += toText(child)
      }
    }
    return newText
  }

  return { parseTemplateText, elementsToText }
}
