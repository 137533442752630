export const authRoutes = {
  login: { name: 'signup', path: 'login' },
  signup: { name: 'login', path: 'signup' },
  logout: { name: 'logout', path: 'logout' },
  expired: { name: 'expired', path: 'expired', props: { expired: true } },
  emailVerification: { name: 'emailVerification', path: 'email-verify' },
  activationRequired: { name: 'activationRequired', path: 'activate' },
  inviteAccept: { name: 'inviteAccept', path: '/invite/:inviteId', props: true },
  forgotPassword: { name: 'forgotPassword', path: 'forgot-password' },
  setPassword: { name: 'setPassword', path: 'reset-password' },
}

export const authLinks = {
  login: () => ({ name: authRoutes.login.name }),
  signup: () => ({ name: authRoutes.signup.name }),
  logout: () => ({ name: authRoutes.logout.name }),
  expired: () => ({ name: authRoutes.expired.name }),
  emailVerification: () => ({ name: authRoutes.emailVerification.name }),
  activationRequired: () => ({ name: authRoutes.activationRequired.name }),
  inviteAccept: (inviteId: string) => ({ name: authRoutes.inviteAccept.name, params: { inviteId } }),
  forgotPassword: () => ({ name: authRoutes.forgotPassword.name }),
  setPassword: () => ({ name: authRoutes.setPassword.name }),
}
