<script lang="ts" setup>
import { Row, Button, Column, FluidGrid, Card } from '@madxnl/dodo-ui'
import { useAvailablePlans, useSignupForm } from '../composables'
import { SubscriptionIntervalEnum, SubscriptionLevelEnum } from '@/generated/sdk'
import { SegmentedControl, TwinIcon } from '@/ui/components'

const { availablePlans } = useAvailablePlans()
const { data, submitStep } = useSignupForm()
const { Yearly, Monthly } = SubscriptionIntervalEnum

type Plan = (typeof availablePlans)[0]

function getPrice(plan: Plan) {
  return data.subscriptionInterval === Yearly ? plan.priceYearly : plan.priceMonthly
}

async function onSelect(plan: Plan) {
  if (plan.subscriptionLevel === SubscriptionLevelEnum.Enterprise) {
    // For Enterprise, open contact form instead of selecting plan
    const contactUrl = 'https://www.twinai.nl/contact'
    window.open(contactUrl, '_blank')
    return
  }
  data.subscriptionLevel = plan.subscriptionLevel
  await submitStep()
}
</script>

<template>
  <SegmentedControl
    v-model="data.subscriptionInterval"
    :options="[
      { name: Monthly.toString(), value: Monthly },
      { name: Yearly.toString(), value: Yearly },
    ]"
    description="Save one month using yearly billing"
  />

  <FluidGrid :column-size="240" gap="l" style="align-items: stretch">
    <Card v-for="plan in availablePlans" :key="plan.name" padding="l" gap="l">
      <Column gap="xs">
        <strong>{{ plan.name }}</strong>

        <Row>
          <h1>{{ getPrice(plan) }}</h1>
          <p v-if="plan.id !== 'simple' && plan.id !== 'enterprise'">
            / {{ data.subscriptionInterval === Yearly ? 'year' : 'month' }}
          </p>
        </Row>
      </Column>

      <Button @click="onSelect(plan)">
        {{ plan.callToAction }}
      </Button>

      <p>{{ plan.description }}</p>

      <Column gap="s">
        <Row v-for="feature in plan.features" :key="feature">
          <TwinIcon icon="CheckCircle" color="primary" />
          {{ feature }}<br />
        </Row>
      </Column>
    </Card>
  </FluidGrid>
</template>
