import { computed, reactive, Ref } from 'vue'

export function usePagination<T>(results: Ref<T[] | undefined>, fetchData: () => Promise<void>) {
  // Simple pagination that just increases the page size
  const pageSize = 20
  const pagination = reactive({ size: pageSize })

  async function loadMore() {
    pagination.size += pageSize
    await fetchData()
  }

  // Show the "Load more" button if there are more results to load
  // (Note we dont have a `count` field in the API response yet)
  const showMore = computed(() => {
    const hasMore = results.value?.length === pagination.size
    return hasMore ? loadMore : null
  })

  return { pagination, showMore }
}
