import { computed } from 'vue'
import { useWorkflowDetails } from './useWorkflowDetails'

export function useEditorState() {
  const { workflow, triggerSaveWorkflow } = useWorkflowDetails()

  function getStateProperty(...keys: string[]) {
    return getPropertyFromUnknown(workflow.value?.editorState, keys)
  }

  function setStateProperty(value: unknown, ...keys: string[]) {
    if (!workflow.value) return
    const state = isRecord(workflow.value.editorState) ? workflow.value.editorState : {}
    setPropertyOnUnknown(state, keys, value)
    workflow.value.editorState = state
    triggerSaveWorkflow()
  }

  function getBlockPosition(id: string): [number, number] {
    const position = getStateProperty('blockPositions', id)
    return isNumberPair(position) ? position : [0, 0]
  }

  function setBlockPosition(id: string, [x, y]: [number, number]) {
    // Round to 2 decimal places
    const rounded = [Math.round(x * 100) / 100, Math.round(y * 100) / 100]
    setStateProperty(rounded, 'blockPositions', id)
    cleanup()
  }

  function checkWorkflowNeedsLayout() {
    // Check if the workflow needs a layout update
    const blockPositions = getStateProperty('blockPositions')
    return !isRecord(blockPositions) || Object.keys(blockPositions).length === 0
  }

  const zoomScale = computed({
    get: () => Number(getStateProperty('zoom')) || 1,
    set: (value) => {
      setStateProperty(value, 'zoom')
    },
  })

  const viewPosition = computed({
    get: (): [number, number] => {
      const xy = getStateProperty('scroll')
      return isNumberPair(xy) ? xy : [0, 0]
    },
    set: (value) => {
      setStateProperty(value, 'scroll')
    },
  })

  const sidebarLeftCollapsed = computed({
    get: (): boolean => {
      return !!getStateProperty('sidebarLeftCollapsed')
    },
    set: (value) => {
      setStateProperty(value, 'sidebarLeftCollapsed')
    },
  })

  const sidebarRightCollapsed = computed({
    get: (): boolean => {
      return !!getStateProperty('sidebarRightCollapsed')
    },
    set: (value) => {
      setStateProperty(value, 'sidebarRightCollapsed')
    },
  })

  function cleanup() {
    // Clean up old positions from the json
    const workflowBlockIds = workflow.value?.workflowBlocks.map((b) => b.id) ?? []
    const positions = getStateProperty('blockPositions') ?? {}
    for (const key in positions) {
      if (isRecord(positions) && !workflowBlockIds.includes(key)) {
        delete positions[key]
      }
    }
  }

  return {
    getBlockPosition,
    setBlockPosition,
    zoomScale,
    viewPosition,
    checkWorkflowNeedsLayout,
    sidebarLeftCollapsed,
    sidebarRightCollapsed,
  }
}

// Helper methods:

function setPropertyOnUnknown(obj: Record<string, unknown>, keys: string[], value: unknown) {
  // Safely set a nested property on an unknown object
  const lastKey = keys.pop()
  if (!lastKey || !isRecord(obj)) return
  let o = obj
  for (const key of keys) {
    const cur = o[key]
    const next = isRecord(cur) ? cur : {}
    o[key] = next
    o = next
  }
  o[lastKey] = value
}

function getPropertyFromUnknown(obj: unknown, keys: string[]): unknown {
  // Safely get a nested property from an unknown object
  let o = obj
  for (const key of keys) {
    if (o === null || typeof o !== 'object') return
    o = (o as Record<string, unknown>)[key]
  }
  return o
}

function isNumberPair(value: unknown): value is [number, number] {
  if (!Array.isArray(value)) return false
  if (value.length < 2) return false
  return typeof value[0] === 'number' && typeof value[1] === 'number'
}

function isRecord(value: unknown): value is Record<string, unknown> {
  return value != null && typeof value === 'object' && !Array.isArray(value)
}
