import { useApiClient } from '@/api'
import { UserDetailsFragment } from '@/generated/sdk'
import { getCurrentScope } from '@sentry/vue'
import { computed, ref, watch } from 'vue'
import { useAuthSession } from '.'

const user = ref<UserDetailsFragment | null>(null)
const updatePromise = ref<Promise<void> | null>(null)
const currentSession = ref<string | null>(null)

export function useCurrentUser() {
  const api = useApiClient()
  const { accessToken } = useAuthSession()

  const currentUser = computed(() => (accessToken.value ? user.value : null))

  function displayName(maxLength = 16) {
    // Returns a shortened version of the user's name, or their email if no name is available
    if (!currentUser.value) return ''
    const { firstName, lastName, email } = currentUser.value
    const name = firstName || lastName || email
    return name.length > maxLength ? name.slice(0, maxLength) + '…' : name
  }

  watch(accessToken, startUpdateUserAsync, { immediate: true })

  watch(user, () => {
    // Update Sentry user context
    const scope = getCurrentScope()
    scope.setUser(user.value ? { ...user.value } : null)
  })

  async function startUpdateUserAsync() {
    updatePromise.value = getUser()
    await updatePromise.value
  }

  async function getUser() {
    if (accessToken.value === currentSession.value) return
    currentSession.value = accessToken.value || null
    if (!accessToken.value) {
      user.value = null
    } else {
      const response = await api.client.me()
      user.value = response.me
    }
  }

  async function waitForUser() {
    await updatePromise.value
    return currentUser.value
  }

  return { currentUser, waitForUser, displayName }
}
