import { useApiClient } from '@/api'
import { BlockArgumentInputType, BlockArgumentType, WorkflowInputArgumentFragment } from '@/generated/sdk'
import { computed } from 'vue'
import { useWorkflowDetails } from '../composables'

export const typeMapping = {
  Checkbox: { argumentType: BlockArgumentType.Boolean, inputType: BlockArgumentInputType.Checkbox },
  Text: { argumentType: BlockArgumentType.String, inputType: BlockArgumentInputType.Text },
  TextArea: { argumentType: BlockArgumentType.String, inputType: BlockArgumentInputType.TextArea },
  Number: { argumentType: BlockArgumentType.Number, inputType: BlockArgumentInputType.Number },
  File: { argumentType: BlockArgumentType.File, inputType: BlockArgumentInputType.File },
  MultiFile: { argumentType: BlockArgumentType.Array, inputType: BlockArgumentInputType.File },
} as const

type FieldType = keyof typeof typeMapping

export const typeOptions = Object.keys(typeMapping).map((type) => ({ label: type, value: type }))

export function useWorkflowSettings() {
  const { client } = useApiClient()
  const { workflow, refreshWorkflow } = useWorkflowDetails()

  const workflowInputArguments = computed(
    () => workflow.value?.inputArguments.sort((a, b) => a.name.localeCompare(b.name)) || [],
  )

  function getTypeName(argument: WorkflowInputArgumentFragment) {
    const entry = Object.entries(typeMapping).find(
      ([key, value]) => value.inputType === argument.inputType && value.argumentType === argument.argumentType,
    )
    return entry ? entry[0] : null
  }

  async function createArgument(name: string) {
    const input = {
      workflow: { id: workflow.value?.id },
      name,
      description: '',
      argumentType: BlockArgumentType.String,
      inputType: BlockArgumentInputType.Text,
      required: false,
    }
    await client.createWorkflowInputArgument({ input })
    await refreshWorkflow()
  }

  async function updateArgument(argument: WorkflowInputArgumentFragment, type?: string) {
    if (type && type in typeMapping) setArgumentType(argument, type as FieldType)
    await client.updateWorkflowInputArgument({ input: argument })
  }

  function setArgumentType(argument: WorkflowInputArgumentFragment, type: FieldType) {
    if (!(type in typeMapping)) throw new Error(`Unknown input type: ${type}`)
    argument.argumentType = typeMapping[type].argumentType
    argument.inputType = typeMapping[type].inputType
  }

  async function deleteArgument(id: string) {
    await client.deleteWorkflowInputArgument({ id })
    await refreshWorkflow()
  }

  return { workflowInputArguments, refreshWorkflow, createArgument, updateArgument, deleteArgument, getTypeName }
}
