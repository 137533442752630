<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { Tabs } from '@madxnl/dodo-ui'
import { useRouter } from 'vue-router'
import { authLinks } from '../routes'

const router = useRouter()

const tabIndex = ref(0)

const tabs = [
  {
    name: 'Log in',
    link: authLinks.login(),
  },
  {
    name: 'I’m new',
    link: authLinks.signup(),
  },
]

onMounted(() => {
  tabs.forEach((tab, index) => {
    if (router.currentRoute.value.name === tab.link.name) {
      tabIndex.value = index
    }
  })
})

async function goToTab(index: number) {
  const tab = tabs[index]
  if (!tab) return
  tabIndex.value = index
  await router.push(tab.link)
}
</script>

<template>
  <Tabs :tabs="tabs" :tab-index="tabIndex" @update:tab-index="goToTab" />
</template>
