<script lang="ts" setup>
import { NavEntry, Navbar } from '@/navigation/components'
import { useNavEntries } from '@/navigation/composables'
import { Column } from '@madxnl/dodo-ui'

const { navEntries } = useNavEntries()

defineProps<{
  overrideNavEntries?: NavEntry[]
  fullScreen?: boolean
}>()
</script>

<template>
  <slot name="navbar">
    <Navbar :nav-entries="overrideNavEntries ?? navEntries" />
  </slot>

  <Column :class="[$style.page, fullScreen && $style.fullScreen]" gap="l">
    <slot />
  </Column>

  <slot name="footer" />
</template>

<style>
body {
  margin: 0;
}
</style>

<style module>
.page {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  padding: var(--dodo-spacing-xl) var(--dodo-spacing-l);
  flex-grow: 1;
  z-index: 0;
}
.fullScreen {
  width: 100%;
}
</style>
