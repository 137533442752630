<script setup lang="ts">
import { Button, Row } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { useChargebee } from '../composables'

const { createChargebeePortal, openChargebeePortal } = useChargebee()

onMounted(async () => {
  await createChargebeePortal()
})
</script>

<template>
  <Row>
    <Button @click="openChargebeePortal('BILLING_HISTORY')">Show invoices</Button>
    <Button @click="openChargebeePortal('PAYMENT_SOURCES')">Payment method</Button>
  </Row>
</template>

<style module></style>
