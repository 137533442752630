<script lang="ts" setup>
import { Column, FormItem, TextInput } from '@madxnl/dodo-ui'
import { useSignupForm } from '../composables'

const { data, errors } = useSignupForm()
</script>

<template>
  <Column gap="l">
    <FormItem label="Firstname" :error="errors.firstName">
      <TextInput id="firstname" v-model="data.firstName" autofocus />
    </FormItem>

    <FormItem label="Lastname" :error="errors.lastName">
      <TextInput id="lastname" v-model="data.lastName" />
    </FormItem>

    <FormItem label="Email" :error="errors.email">
      <TextInput id="email" v-model="data.email" type="email" autocomplete="email" />
    </FormItem>

    <FormItem label="Company name" :error="errors.organizationName">
      <TextInput id="company" v-model="data.organizationName" autocomplete="organization" />
    </FormItem>

    <FormItem label="Password" :error="errors.password">
      <TextInput id="password" v-model="data.password" type="password" />
    </FormItem>
  </Column>
</template>
