import { useApiClient } from '@/api'
import { useValidatePassword } from '@/auth/composables/useValidatePassword'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'

export function useInviteAcceptForm(inviteId: string) {
  const { client } = useApiClient()

  const password = ref('')
  const password2 = ref('')
  const data = reactive({ password, password2 })
  const notFound = ref(false)
  const { validatePassword } = useValidatePassword()

  function passwordsMatch(password2: string) {
    if (password.value !== password2) return 'Passwords do not match'
    return undefined
  }

  const { errors, validate } = useValidation({
    password: { value: password, required: true, validators: [validatePassword] },
    password2: { value: password2, required: true, validators: [passwordsMatch] },
  })

  async function checkExists(inviteId: string) {
    const result = await client.checkInviteExists({ inviteId })
    notFound.value = !result.checkInviteExists
    return result.checkInviteExists
  }

  async function submit(inviteId: string) {
    const exists = await checkExists(inviteId)
    if (!exists) return

    const isValid = await validate()
    if (!isValid) return

    const result = await client.acceptInvite({ inviteeId: inviteId, password: password.value })
    return result.completeRegistration
  }

  return { data, submit, errors, checkExists, notFound }
}
