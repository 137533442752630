<script lang="ts" setup>
import { TwinIcon } from '@/ui/components'
import { Button, Column, FormItem, TextInput } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { RouterLink, useRouter } from 'vue-router'
import { AuthPageLayout } from '.'
import { useAuthSession, useInviteAcceptForm } from '../composables'
import { authLinks } from '../routes'

const props = defineProps<{
  inviteId: string
}>()

const { logoutAuth } = useAuthSession()
const { data, errors, submit, checkExists, notFound } = useInviteAcceptForm(props.inviteId)
const router = useRouter()

onMounted(async () => {
  logoutAuth()
  await checkExists(props.inviteId)
})

async function clickSubmit() {
  const result = await submit(props.inviteId)
  if (result) await router.push(authLinks.login())
}
</script>

<template>
  <AuthPageLayout title="You have been invited">
    <Column v-if="notFound">
      <h4>Invitation is no longer valid</h4>
      <p>
        This invite is either invalid, has expired or has already been used. Contact your administrator to ask for this
        invite to be sent again.
      </p>
    </Column>
    <Column v-else gap="l">
      <Column>
        <FormItem label="Password" :error="errors.password">
          <TextInput v-model="data.password" type="password" />
        </FormItem>

        <FormItem label="Password (confirm)" :error="errors.password2">
          <TextInput v-model="data.password2" type="password" />
        </FormItem>
      </Column>

      <Column>
        <Button variant="solid" color="primary" @click="clickSubmit">Accept invite</Button>
      </Column>
    </Column>

    <template #formActions>
      <RouterLink v-slot="{ navigate }" :to="authLinks.login()">
        <Button variant="link" color="primary" @click="navigate">
          <TwinIcon icon="ChevronLeft" />
          Back to login
        </Button>
      </RouterLink>
    </template>
  </AuthPageLayout>
</template>
