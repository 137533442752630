import { useApiClient } from '@/api'
import { ClientError } from 'graphql-request'
import { ref } from 'vue'

const EMAIL_ALREADY_VERIFIED = '#0303'

export function useEmailVerification() {
  const { client } = useApiClient()
  const verifying = ref(true)
  const message = ref('')

  async function submit(email: string, token: string) {
    try {
      await client.verifyEmail({ email, token })
      verifying.value = false
      message.value = 'Email address successfully verified!'
    } catch (err) {
      verifying.value = false

      if (err instanceof ClientError && err.message.includes(EMAIL_ALREADY_VERIFIED)) {
        message.value = 'Email address already verified.'
        return
      }
      message.value = 'Something went wrong'
      throw err
    }
  }

  return { submit, verifying, message }
}
