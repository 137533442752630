<script setup lang="ts">
import { useApiClient } from '@/api'
import { BlockConfigArgumentType, WorkflowBlockItemFragment } from '@/generated/sdk'
import { FormItem, Select, Spinner } from '@madxnl/dodo-ui'
import { computed, watch } from 'vue'
import { useBlockTypes, useWorkflowDetails } from '../composables'
import { useDataSources } from './useDataSources'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment
}>()

const { client } = useApiClient()
const { blockTypes, fetchBlockTypeByName } = useBlockTypes()
const { workflow, refreshWorkflow, savePendingChanges, triggerSaveWorkflow } = useWorkflowDetails()
const { getAvailableDataSources } = useDataSources()

const blockConfig = computed(() => props.workflowBlock.blockConfig)
const isLoopBlock = computed(() => blockConfig.value.block.includes('loop'))
const innerConfig = computed(() => blockConfig.value.loop?.blockConfig)
const listArgument = computed(() => blockConfig.value?.arguments.find((x) => x.name === 'input'))
const availableBlockResults = computed(() => getAvailableDataSources(props.workflowBlock, { excludeLoopEntry: true }))
const hasListAndInnerConfig = computed(() => !!listArgument.value && !!innerConfig.value)
const needsConfiguring = computed(() => isLoopBlock.value !== hasListAndInnerConfig.value)

watch(needsConfiguring, configureBlockForLoop, { immediate: true })

async function configureBlockForLoop() {
  if (!needsConfiguring.value) return
  await savePendingChanges()
  if (isLoopBlock.value) {
    if (!listArgument.value) await createLoopArgument()
    if (!innerConfig.value) await createLoopConfig()
  } else {
    if (blockConfig.value.loop) await deleteLoop(blockConfig.value.loop.id)
  }
  await refreshWorkflow()
}

async function deleteLoop(loopId: string) {
  await client.deleteLoop({ loopId })
}

async function createLoopArgument() {
  const result = await client.createBlockConfigArgument({
    input: {
      name: 'input',
      argumentType: BlockConfigArgumentType.Reference,
      value: '',
      blockConfig: { id: blockConfig.value.id },
    },
  })
  return result.createBlockConfigArgument
}

async function createLoopConfig() {
  await client.updateWorkflowBlock({
    input: {
      id: props.workflowBlock.id,
      blockConfig: {
        id: blockConfig.value.id,
        inheritContext: true,
        loop: {
          id: blockConfig.value.loop?.id,
          blockConfig: {
            block: (await fetchBlockTypeByName('prompt')).id,
            arguments: [],
          },
        },
      },
    },
  })
}
</script>

<template>
  <template v-if="isLoopBlock">
    <h4>Loop block settings</h4>

    <FormItem label="List" description="Select a list to loop over">
      <Select
        v-if="listArgument"
        v-model="listArgument.value"
        :options="availableBlockResults.flatMap((g) => g.references)"
        :disabled="!workflow?.draft"
        @update:model-value="triggerSaveWorkflow"
      />
      <Spinner v-else />
    </FormItem>

    <FormItem v-if="innerConfig" label="Loop block">
      <Select
        v-if="blockTypes"
        v-model="innerConfig.block"
        :disabled="!workflow?.draft"
        :options="blockTypes.filter((x) => !x.id.includes('loop')).map((b) => ({ label: b.readableName, value: b.id }))"
        @update:model-value="triggerSaveWorkflow"
      />
      <Spinner v-else />
    </FormItem>
  </template>
</template>
