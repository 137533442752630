<script lang="ts" setup>
import { Button, Column, Spinner } from '@madxnl/dodo-ui'
import { useEmailVerification } from '../composables'
import AuthPageLayout from './AuthPageLayout.vue'
import { onMounted } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { authLinks } from '../routes'

const route = useRoute()

const { submit, verifying, message } = useEmailVerification()

onMounted(async () => {
  await submit(route.query.email as string, route.query.token as string)
})
</script>

<template>
  <AuthPageLayout title="Account verification">
    <Column v-if="verifying">
      <p>Verifying your email address...</p>
      <Spinner />
    </Column>
    <Column v-else gap="l">
      <p>{{ message }}</p>
      <RouterLink :to="authLinks.login()">
        <Button variant="solid" color="primary" style="width: 100%">Log in</Button>
      </RouterLink>
    </Column>
  </AuthPageLayout>
</template>
