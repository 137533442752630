import { BlockArgumentDetailsFragment, BlockArgumentType, BlockConfigArgumentFragment } from '@/generated/sdk'
import { computed } from 'vue'
import { useBlockSettingsContext } from './useBlockSettingsContext'
import { useManageArguments } from './useManageArguments'

export type SettingsField = {
  data: unknown
  namePath: string[]
  blockTypeArg: BlockArgumentDetailsFragment | null
  argument: BlockConfigArgumentFragment | null
  properties?: SettingsField[]
  items?: SettingsField[]
}

export function useFieldGeneration() {
  const { blockType } = useBlockSettingsContext()
  const { configArgs } = useManageArguments()

  function generateFieldsRecursively(
    data: unknown,
    namePath: string[],
    blockTypeArg: BlockArgumentDetailsFragment | null,
  ) {
    const argument = configArgs.value.find((a) => a.name === namePath[0]) ?? null
    if (data == null && blockTypeArg?.defaultValue != null) {
      data = blockTypeArg?.defaultValue
    }
    const field: SettingsField = { data, namePath, argument, blockTypeArg }
    const dataObj = data && typeof data === 'object' ? (data as Record<string, unknown>) : {}
    if (blockTypeArg?.argumentType === BlockArgumentType.Array && blockTypeArg.items) {
      const argItems = Array.isArray(data) ? data : []
      field.items = []
      for (let i = 0; i < argItems.length; i++) {
        const subData = argItems[i]
        const subPath = [...namePath, String(i)]
        field.items.push(generateFieldsRecursively(subData, subPath, blockTypeArg.items))
      }
    } else if (blockTypeArg?.argumentType === BlockArgumentType.Object && blockTypeArg?.properties) {
      field.properties = []
      for (const property of blockTypeArg.properties) {
        if (!property.name) continue
        const subData = dataObj[property.name]
        const subPath = [...namePath, property.name]
        field.properties.push(generateFieldsRecursively(subData, subPath, property))
      }
    }
    return field
  }

  function generateFields() {
    const fields: SettingsField[] = []
    const blockTypeArgs = blockType.value?.arguments || []
    for (const blockTypeArg of blockTypeArgs) {
      if (!blockTypeArg.name) continue
      const argument = configArgs.value.find((a) => a.name === blockTypeArg.name)
      const argData = parseArgJson(argument?.value)
      fields.push(generateFieldsRecursively(argData, [blockTypeArg.name], blockTypeArg))
    }
    for (const argument of configArgs.value) {
      // Add custom arguments that are not defined in the block type
      if (blockTypeArgs.some((a) => a.name === argument.name)) continue
      const argData = parseArgJson(argument?.value)
      fields.push(generateFieldsRecursively(argData, [argument.name], null))
    }
    return fields
  }

  function parseArgJson(data: undefined | string | null) {
    try {
      return data ? JSON.parse(data) : null
    } catch (e) {
      return data ?? null
    }
  }

  const generatedFields = computed(generateFields)

  const flatFields = computed(() => {
    const list: SettingsField[] = []
    const queue = [...generatedFields.value]
    while (queue.length > 0) {
      const field = queue.shift()!
      list.push(field)
      if (field.properties) queue.push(...field.properties)
      if (field.items) queue.push(...field.items)
    }
    return list
  })

  function getParentField(field: SettingsField) {
    const parentPath = field.namePath.slice(0, -1)
    return flatFields.value.find((f) => String(f.namePath) === String(parentPath))
  }

  return { generatedFields, getParentField }
}
