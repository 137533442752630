<script setup lang="ts">
import { WorkflowInputArgumentFragment } from '@/generated/sdk'
import { LargeModalLayout, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, Column, Form, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watchEffect } from 'vue'
import { useWorkflowSettingForm } from './useWorkflowSettingForm'
import { typeOptions, useWorkflowSettings } from './useWorkflowSettings'

const { updateArgument, deleteArgument, refreshWorkflow, getTypeName } = useWorkflowSettings()
const { data, load, reset, errors, submit: submitForm } = useWorkflowSettingForm()
const { showMessage } = useSimpleMessage()

const props = defineProps<{
  setting: WorkflowInputArgumentFragment
  disabled: boolean
}>()

const isEditing = ref(false)

const type = computed(() => getTypeName(props.setting))

watchEffect(() => (isEditing.value ? load(props.setting) : reset()))

async function clickSubmit() {
  const submitted = await submitForm()
  if (!submitted) return
  isEditing.value = false
  await refreshWorkflow()
  showMessage('Input data field updated')
}
</script>

<template>
  <Column gap="s">
    <Row justify="between">
      <h5>{{ setting.name }}</h5>
      <Row>
        <Button color="secondary" square size="s" variant="link" :disabled="disabled" @click="isEditing = true">
          <TwinIcon size="s" icon="Edit" />
        </Button>
        <Button
          color="secondary"
          square
          size="s"
          variant="link"
          :disabled="disabled"
          @click="deleteArgument(setting.id)"
        >
          <TwinIcon size="s" icon="Delete" />
        </Button>
      </Row>
    </Row>

    <FormItem :description="setting.description">
      <Select
        :model-value="type"
        :options="typeOptions"
        :disabled="disabled"
        @update:model-value="(type) => updateArgument(setting, type ?? undefined)"
      />
    </FormItem>
  </Column>

  <LargeModalLayout :open="isEditing" title="Edit input data field" @close="isEditing = false">
    <template #content>
      <Form @submit="clickSubmit">
        <FormItem label="Name" :error="errors.name">
          <TextInput v-model="data.name" />
        </FormItem>
        <FormItem label="Description" :error="errors.description">
          <TextInput v-model="data.description" />
        </FormItem>
        <Row>
          <input id="required" v-model="data.required" type="checkbox" />
          <label for="required">Required</label>
        </Row>
        <FormItem label="Input type" :error="errors.type">
          <Select v-model="data.type" :options="typeOptions" />
        </FormItem>
      </Form>
    </template>
    <template #footer="{ close }">
      <Button @click="close()">Cancel</Button>
      <Button variant="solid" color="primary" type="submit" @click.prevent="clickSubmit">Save</Button>
    </template>
  </LargeModalLayout>
</template>
