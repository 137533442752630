<script setup lang="ts">
import { WorkflowBlockType } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useOpenRunBlockModal } from '@/ui/composables'
import RunBlockConfigModal from '@/workflows/blockconfig/RunBlockConfigModal.vue'
import { Button, FormItem, Icon, Row, Tabs, Textarea, TextInput, useValidation } from '@madxnl/dodo-ui'
import { computed, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { BlockOutput, BlockSettings, WorkflowSettings } from '.'
import { useEditorLinks, useWorkflowDetails, useWorkflowEditor } from '../composables'
import LoopBlockSettings from './LoopBlockSettings.vue'
import SelectBlockType from './SelectBlockType.vue'
import SelectSubworkflow from './SelectSubworkflow.vue'

const router = useRouter()
const { linkReviewData } = useEditorLinks()
const { showRunBlockModal } = useOpenRunBlockModal()
const { workflow, triggerSaveWorkflow } = useWorkflowDetails()
const {
  selectedBlock,
  selectedBlockName,
  sidebarTab,
  removeBlock,
  getNextBlocks,
  addNewBlock,
  getBlockOutputConditions,
  checkNameExists,
} = useWorkflowEditor()

const newCondition = ref('')
const newTitle = ref()

const titleValidator = (title?: string) =>
  title && checkNameExists(title, selectedBlock.value?.id) ? 'The title must be unique.' : undefined

const { validate, errors } = useValidation({
  title: { value: newTitle, required: true, maxLen: 50, validators: [titleValidator] },
})

const workflowSettingsTabs = [
  { key: 'input' as const, name: 'Input', slot: 'input' },
  { key: 'output' as const, name: 'Output', slot: 'output' },
]
const blockSettingsTabs = [
  { key: 'general' as const, name: 'General', slot: 'general' },
  { key: 'settings' as const, name: 'Settings', slot: 'settings' },
  { key: 'output' as const, name: 'Output', slot: 'output' },
]

const nextBlocks = computed(() => getNextBlocks(selectedBlock.value!))
const allowDeleteBlock = computed(() => workflow.value?.draft && workflow.value.workflowBlocks.length !== 1)
const isWorkflowBlock = computed(() => selectedBlock.value?.blockConfig.block.includes('workflow') ?? false)
const isWorkflowLoopBlock = computed(() => loopConfig.value?.block.includes('workflow') ?? false)
const loopConfig = computed(() => selectedBlock.value?.blockConfig.loop?.blockConfig)

onUnmounted(() => {
  selectedBlock.value = undefined
  newCondition.value = ''
})

async function clickNewCondition() {
  const condition = newCondition.value
  await addNewBlock({ condition, previousBlocks: [{ id: selectedBlock.value!.id }] }, { select: false })
  newCondition.value = ''
}

async function updateSelectedBlockTitle(title: string | null) {
  newTitle.value = title
  const valid = await validate()
  if (!valid) return
  selectedBlock.value!.name = newTitle.value
  triggerSaveWorkflow()
}

function modifyCondition(oldCondition: string | null | undefined, newCondition: string | null) {
  for (const b of nextBlocks.value) {
    if (b.condition === oldCondition) {
      b.condition = newCondition
    }
  }
  triggerSaveWorkflow()
}

async function clickDelete() {
  await removeBlock(selectedBlock.value!)
}

async function handleSubmitRunBlockConfig() {
  await router.push(linkReviewData(workflow.value!.id, selectedBlock.value!.id))
}
</script>

<template>
  <template v-if="workflow">
    <template v-if="selectedBlock == null">
      <FormItem label="Name">
        <TextInput v-model="workflow.name" :disabled="!workflow?.draft" @update:model-value="triggerSaveWorkflow" />
      </FormItem>

      <FormItem label="Description" optional>
        <Textarea
          v-model="workflow.description"
          :min-rows="2"
          :max-rows="6"
          placeholder="Add a description"
          :disabled="!workflow?.draft"
          @update:model-value="triggerSaveWorkflow"
        />
      </FormItem>

      <Tabs :tabs="workflowSettingsTabs">
        <template #input>
          <WorkflowSettings />
        </template>

        <template #output>
          <BlockSettings :block-config="undefined" context="output" />
        </template>
      </Tabs>
    </template>

    <template v-else>
      <Tabs
        :tabs="blockSettingsTabs"
        :tab-index="blockSettingsTabs.findIndex((t) => t.key === sidebarTab)"
        @update:tab-index="sidebarTab = blockSettingsTabs[$event]!.key"
      >
        <template #general>
          <FormItem label="Name" :error="errors.title">
            <TextInput
              :model-value="selectedBlock.name"
              name="name"
              :placeholder="selectedBlockName"
              :disabled="!workflow?.draft"
              @update:model-value="updateSelectedBlockTitle"
            />
          </FormItem>
          <FormItem v-if="selectedBlock.blockType === WorkflowBlockType.Switch" label="Conditions">
            <template v-for="(condition, key) in getBlockOutputConditions(selectedBlock)" :key="key">
              <TextInput
                :model-value="condition"
                :disabled="!workflow?.draft"
                @change="modifyCondition(condition, $event.target.value)"
              />
            </template>
            <Row>
              <TextInput v-model="newCondition" placeholder="Condition to add" />
              <Button @click="clickNewCondition">
                <Icon name="Add" size="s" />
                Add
              </Button>
            </Row>
          </FormItem>
          <SelectBlockType :workflow-block="selectedBlock" />
          <LoopBlockSettings :workflow-block="selectedBlock" />
          <Button variant="solid" color="primary" @click="showRunBlockModal">
            <TwinIcon icon="Play" />
            Run block
          </Button>
          <Button :disabled="!allowDeleteBlock" color="danger" @click="clickDelete"> Delete </Button>
        </template>

        <template #settings>
          <SelectSubworkflow v-if="isWorkflowBlock" :block-config="selectedBlock.blockConfig" />
          <SelectSubworkflow v-if="loopConfig && isWorkflowLoopBlock" :block-config="loopConfig" />
          <BlockSettings v-if="loopConfig" :block-config="loopConfig" context="settings" />
          <BlockSettings v-else :block-config="selectedBlock.blockConfig" context="settings" />
        </template>

        <template #output>
          <BlockOutput :workflow-id="workflow!.id" :workflow-block="selectedBlock" />
        </template>
      </Tabs>

      <RunBlockConfigModal :block-config="selectedBlock?.blockConfig" @submit="handleSubmitRunBlockConfig" />
    </template>
  </template>
</template>
