<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  amount: number
}>()

const local = computed(() => props.amount.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }))
</script>

<template>{{ local }}</template>
