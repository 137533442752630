<script setup lang="ts">
import { BlockConfigArgumentType } from '@/generated/sdk'
import { MainPageLayout, NavEntry, Navbar } from '@/navigation/components'
import { TwinIcon } from '@/ui/components'
import { useOpenRunBlockModal } from '@/ui/composables'
import { EditorBlockCard, useBlockOutput, useEditorLinks, useWorkflowDetails, useWorkflowEditor } from '@/workflow-edit'
import RunBlockConfigModal from '@/workflows/blockconfig/RunBlockConfigModal.vue'
import { Button, Column, Row, TextInput } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import DataActionBar from './DataActionBar.vue'
import DataPageTable from './DataPageTable.vue'
import { useWorkflowBlock } from './useWorkflowBlock'

const navEntries: NavEntry[] = [
  { position: 'start', isSeparator: true },
  { position: 'start', slot: 'back' },
  { position: 'end', slot: 'run' },
]

const router = useRouter()
const editorLinks = useEditorLinks()
const { showRunBlockModal } = useOpenRunBlockModal()
const { workflow } = useWorkflowDetails()
const { selectedBlock, sidebarTab, getBlock } = useWorkflowEditor()
const { workflowBlock, fetch: fetchWorkflowBlock } = useWorkflowBlock()
const blockOutput = useBlockOutput()

const props = defineProps<{
  workflowId: string
  workflowBlockId: string
}>()

const isInfoShown = ref(true)

watch(props, fetch, { immediate: true })

async function fetch() {
  await fetchWorkflowBlock(props.workflowBlockId)
  if (!workflowBlock.value) return
  await blockOutput.fetchOutput(workflowBlock.value.blockConfig.id)
}

async function handleReturn() {
  await router.push(editorLinks.linkBackToWorkflowEdit(workflow.value!.id))
}

async function handleEditBlock() {
  selectedBlock.value = getBlock(props.workflowBlockId)!
  sidebarTab.value = 'settings'
  await router.push(editorLinks.linkBackToWorkflowEdit(workflow.value!.id))
}
</script>

<template>
  <MainPageLayout full-screen>
    <template #navbar>
      <Navbar :nav-entries="navEntries">
        <template #back>
          <Button variant="link" color="primary" @click="handleReturn">
            <TwinIcon icon="ChevronLeft" />
            Back to workflow editor
          </Button>
        </template>

        <template #run>
          <Row gap="xs">
            <Button variant="solid" color="primary" @click="showRunBlockModal">
              <TwinIcon icon="Play" />
              Run block
            </Button>
          </Row>
        </template>
      </Navbar>
    </template>

    <Column gap="xl">
      <Column>
        <h1>Review data</h1>
        <Column v-if="isInfoShown" style="max-width: 770px">
          <p>
            If the block's output data looks wrong, review the block settings and the input data. If the input data is
            wrong, edit the input data. Then re-run the block to find out whether this improves the output. This is a
            temporary action — what happens in this screen stays in this screen.
          </p>
          <Row>
            <Button variant="link" color="primary" @click="isInfoShown = false"> Got it </Button>
          </Row>
        </Column>
      </Column>

      <Row gap="xl" align="start">
        <Column gap="l" style="flex: 0 0 256px">
          <h3>Block</h3>

          <EditorBlockCard
            v-if="workflowBlock"
            :workflow-block="workflowBlock"
            :block-config="workflowBlock.blockConfig"
            :disabled="false"
            selected
            is-data-review
          />
        </Column>

        <Column gap="l" grow>
          <Row justify="between">
            <h3>Block settings</h3>

            <Button variant="link" color="primary" style="margin-right: 0" @click="handleEditBlock">
              Edit settings
            </Button>
          </Row>

          <template v-if="workflowBlock">
            <table :class="$style.table">
              <tr v-for="arg in workflowBlock.blockConfig.arguments" :key="arg.name">
                <td style="width: 256px">{{ arg.name }}</td>
                <td v-if="arg.argumentType === BlockConfigArgumentType.Reference">
                  <em>{{ arg.value }}</em>
                </td>
                <td v-else>{{ arg.value }}</td>
              </tr>
            </table>
          </template>
        </Column>
      </Row>

      <Column>
        <Row justify="between">
          <h3>Block output & input data</h3>
          <Row gap="m">
            <Button disabled>Edit columns</Button>
            <TextInput placeholder="Search" disabled />
          </Row>
        </Row>

        <DataPageTable
          v-if="workflowBlock"
          :block-config="workflowBlock.blockConfig"
          :block-output="blockOutput"
          :input-arguments="workflowBlock.blockConfig.arguments"
        />
      </Column>
    </Column>

    <template #footer>
      <DataActionBar />
    </template>
  </MainPageLayout>

  <RunBlockConfigModal :block-config="workflowBlock?.blockConfig" @submit="fetch" />
</template>

<style module>
.table {
  border-bottom: 2px solid #eee;
}

.table td {
  padding: 8px 0;
  border-top: 2px solid #eee;
}

.colname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
