import { onUnmounted } from 'vue'

export function useAnimationFrame() {
  const handlers = new Set<() => Promise<void> | void>()
  let running = false

  onUnmounted(() => {
    handlers.clear()
    running = false
  })

  function loop() {
    if (!running) return
    handlers.forEach((fn) => {
      fn()?.catch(console.error) // eslint-disable-line no-console
    })
    requestAnimationFrame(loop)
    // setTimeout(loop, 100)
  }

  function onAnimationFrame(fn: () => Promise<void> | void) {
    handlers.add(fn)
    if (!running) {
      running = true
      loop()
    }
  }

  return { onAnimationFrame }
}
