<script lang="ts" setup>
import { useSimpleMessage } from '@/ui/composables'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const { showMessage } = useSimpleMessage()
const router = useRouter()
onMounted(async () => {
  showMessage('Page not found, you have been redirected', { type: 'danger', duration: 5000 })
  await router.push('/')
})
</script>
