import { useApiClient } from '@/api'
import { useCurrentUser } from '@/auth/composables'
import { useValidatePassword } from '@/auth/composables/useValidatePassword'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'

const oldPassword = ref('')
const newPassword = ref('')
const data = reactive({ oldPassword, newPassword })
const saved = ref(false)
const { validatePassword } = useValidatePassword()

export function usePasswordChangeForm() {
  const { client } = useApiClient()
  const { currentUser } = useCurrentUser()
  const { errors, validate } = useValidation({
    oldPassword: { value: oldPassword, required: true },
    newPassword: { value: newPassword, required: true, minLen: 8, validators: [validatePassword] },
  })

  function reset() {
    oldPassword.value = ''
    newPassword.value = ''
  }

  async function submit() {
    saved.value = false
    const isValid = await validate()
    if (!isValid) return

    const userId = currentUser.value!.id
    await client.changePassword({ userId, newPassword: newPassword.value })
    reset()
    saved.value = true
  }

  return { data, reset, submit, errors, saved }
}
