<script setup lang="ts">
import { WorkflowBlockItemFragment, WorkflowBlockType } from '@/generated/sdk'
import { SearchableList, TwinIcon } from '@/ui/components'
import { Button, Dropdown, Row } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useBlockTypes } from '../composables/useBlockTypes'
import { useEditorLinks } from '../composables/useEditorLinks'
import { useWorkflowEditor } from '../composables/useWorkflowEditor'
import EditorBlockConnectorButton from './EditorBlockConnectorButton.vue'

const { linkReviewData } = useEditorLinks()
const { getPreviousBlocks, getNextBlocks } = useWorkflowEditor()
const { getBlockTypeDetails } = useBlockTypes()
const router = useRouter()

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment | null
  isInput?: boolean
  condition?: string
  hoverState?: boolean
}>()

const menuOpen = ref(false)

const isSwitch = computed(() => props.workflowBlock?.blockType === WorkflowBlockType.Switch)
const isIfElse = computed(() => props.workflowBlock?.blockType === WorkflowBlockType.IfElse)

const blocks = computed(() => {
  if (!props.workflowBlock) throw new Error('No workflow block provided')
  if (props.isInput) return getPreviousBlocks(props.workflowBlock)
  const nextBlocks = getNextBlocks(props.workflowBlock)
  if (isSwitch.value || isIfElse.value) return nextBlocks.filter((b) => b.condition === props.condition)
  return nextBlocks
})

const listOptions = computed(() => {
  const items = blocks.value.map((block) => ({
    name: block.name || getBlockTypeDetails(block.blockConfig.block).readableName,
    id: block.id,
    blockType: block.blockConfig.block,
  }))
  return [
    {
      groupName: null,
      items,
    },
  ]
})

const icon = computed(() => {
  if (blocks.value.length > 1) return 'More'
  return props.isInput ? 'ArrowUp' : isSwitch.value ? 'ArrowRight' : 'ArrowDown'
})

async function onClickConnector() {
  if (blocks.value.length === 1) {
    return await reviewBlock(blocks.value[0]?.id)
  }
  menuOpen.value = true
}

async function reviewBlock(blockId?: string) {
  if (!blockId) return
  const workflowId = router.currentRoute.value.params.workflowId
  if (!workflowId || typeof workflowId !== 'string') return
  await router.push(linkReviewData(workflowId, blockId))
  menuOpen.value = false
}

function reset() {
  menuOpen.value = false
}
</script>

<template>
  <Dropdown v-if="blocks.length > 0" v-model:open="menuOpen">
    <template #trigger>
      <EditorBlockConnectorButton :icon="icon" :hover-state="hoverState" @click.prevent="onClickConnector" />
    </template>

    <template #content>
      <Row padding="m">
        <Row grow>
          <h3>{{ isInput ? 'Previous blocks' : 'Next blocks' }}</h3>
        </Row>
        <Button variant="link" square @click.stop="reset">
          <TwinIcon icon="Cross" />
        </Button>
      </Row>
      <SearchableList :grouped-options="listOptions" list-style="block" @select="reviewBlock" />
    </template>
  </Dropdown>
</template>
