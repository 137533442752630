<script lang="ts" setup>
import { Animated } from '@madxnl/dodo-ui'
import { useSimpleMessage } from '../composables'

const { currentMessage } = useSimpleMessage()
</script>

<template>
  <Animated>
    <div v-if="currentMessage" :class="[$style.modal, $style[currentMessage.type]]">
      <p>{{ currentMessage.text }}</p>
    </div>
  </Animated>
</template>

<style module>
.modal {
  position: fixed;
  top: 72px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  font-weight: var(--dodo-bold);
  padding: 4px 16px;
  border-radius: 32px;
  white-space: nowrap;
}
.success {
  background: var(--dodo-color-success-light);
  color: var(--dodo-color-success);
}
.danger {
  background: var(--dodo-color-danger-light);
  color: var(--dodo-color-danger);
}
</style>
