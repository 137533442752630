<script setup lang="ts">
import { TwinIcon } from '@/ui/components'
import { Chip } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { RouterLink } from 'vue-router'
import { useEditorBreadcrumbs, useEditorLinks, useWorkflowDetails } from './composables'

const limit = 3
const editorLinks = useEditorLinks()
const { breadcrumbs, load, currentQuery } = useEditorBreadcrumbs()
const { workflow } = useWorkflowDetails()

watch(currentQuery, load, { immediate: true })
</script>

<template>
  <div :class="$style.breadcrumbs">
    <template v-for="(crumb, i) in breadcrumbs.slice(-limit)" :key="crumb.id">
      <TwinIcon v-if="i" icon="ChevronRight" />
      <RouterLink :to="editorLinks.linkParentWorkflowEdit(crumb.id)">
        <Chip style="cursor: inherit">{{ crumb.name }}</Chip>
      </RouterLink>
    </template>
    <template v-if="workflow && breadcrumbs.length > 0">
      <TwinIcon icon="ChevronRight" />
      <span>{{ workflow.name }}</span>
    </template>
  </div>
</template>

<style module>
.breadcrumbs {
  position: absolute;
  background: red;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  background-color: var(--color-background);
  border-bottom: 1px solid var(--color-border);
}
</style>
