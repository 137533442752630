<script setup lang="ts">
import { RunStatus } from '@/generated/sdk'
import { Chip, ColorProp } from '@madxnl/dodo-ui'
import { computed } from 'vue'

const props = defineProps<{
  runStatus?: RunStatus | null
}>()

const color = computed<ColorProp>(() => {
  switch (props.runStatus) {
    case RunStatus.Done:
      return 'primary'
    case RunStatus.Pending:
      return 'warning'
    case RunStatus.Running:
      return 'success'
    case RunStatus.Failed:
      return 'danger'
    default:
      return 'text'
  }
})
</script>

<template>
  <span v-if="runStatus == null" class="dodo-fade-secondary">No runs yet</span>
  <Chip v-else :color="color">{{ runStatus }}</Chip>
</template>
