<script setup lang="ts">
import { BlockConfigArgumentType } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, Column, Row } from '@madxnl/dodo-ui'
import { computed, onBeforeUnmount, onMounted, ref, useCssModule, watch } from 'vue'
import { SettingsField } from '../sidebar'
import { useManageArguments } from '../sidebar/useManageArguments'
import { useCaretPosition, useTemplateAST } from './composables'

defineProps<{
  field: SettingsField
  disabled?: boolean
  readonly?: boolean
}>()

const templateText = defineModel<string>({ required: true })
const container = ref<HTMLElement>()
const manageArgs = useManageArguments()
const { showMessage } = useSimpleMessage()
const css = useCssModule()
const { getCaretPosition, caretPosition, applyCaretPosition } = useCaretPosition()
const { parseTemplateText, elementsToText } = useTemplateAST(css, getArgByName)

function getArgByName(name: string) {
  return manageArgs.configArgs.value.find((a) => a.name === name) ?? null
}

onMounted(() => {
  document.addEventListener('selectionchange', selectionchange)
})

onBeforeUnmount(() => {
  document.removeEventListener('selectionchange', selectionchange)
})

const html = computed(() => parseTemplateText(templateText.value))

watch([html, container], updateHtml, { immediate: true })

async function updateHtml() {
  // Update the html content of the container if changed
  if (!container.value) return
  container.value.innerHTML = html.value
  await applyCaretPosition(container.value)
}

async function clickInsert() {
  const name = window.prompt('Enter the name of the variable', '')
  if (!name) return
  const nameTaken = manageArgs.configArgs.value.find((a) => a.name === name)
  if (nameTaken) return showMessage('Name is already taken', { type: 'danger' })
  if (!manageArgs.canAddExtraArgument.value) throw new Error('Cannot add variables')
  await manageArgs.addExtraArgument(name, BlockConfigArgumentType.Constant)
  container.value?.focus()
  const newSpan = ` {{ ${name} }} `
  insertAtCaret(newSpan)
}

function insertAtCaret(text: string) {
  if (!container.value) return
  const current = elementsToText(container.value)
  const pos = caretPosition.value ?? current.length
  caretPosition.value = pos + text.length
  templateText.value = current.slice(0, pos) + text + current.slice(pos)
}

function onKeydown(event: KeyboardEvent) {
  if (event.key === 'Tab') {
    event.preventDefault()
    insertAtCaret('  ')
  } else if (event.key === 'Enter') {
    event.preventDefault()
    insertAtCaret('\n')
  }
}

function blur() {
  if (!container.value) return
  templateText.value = elementsToText(container.value)
}

function selectionchange() {
  if (container.value) getCaretPosition(container.value)
}
</script>

<template>
  <Column gap="xs" grow>
    <Row v-if="!readonly" justify="end" gap="0">
      <Button variant="clear" size="s" square title="Insert variable" :disabled="disabled" @click="clickInsert">
        <TwinIcon icon="Plus" size="s" />
      </Button>
    </Row>

    <!-- prettier-ignore -->
    <div
      ref="container" :class="$style.field" tabindex="0" spellcheck="false"
      :contenteditable="disabled ? 'false' : 'true'"
      @blur="blur"
      @keydown="onKeydown"
    ></div>
  </Column>
</template>

<style module>
.field {
  border: 1px solid var(--grey-3-outlines);
  background: var(--grey-0-white);
  padding: 4px 8px;
  border-radius: 8px;
  cursor: text;
  font-size: 16px;
  min-height: 120px;
  line-height: 1.75;
  font-family: monospace;
  white-space: break-spaces;
  overflow: auto;
  scrollbar-width: thin;
}
.field:focus-within {
  border-color: blue;
}
.part {
  display: inline;
  text-overflow: ellipsis;
  overflow: hidden;
}
.var {
  border-radius: 4px;
  line-height: 1.5;
  background: var(--grey-2-lines);
  display: inline-block;
  outline: 1px solid var(--grey-3-outlines);
}
.noarg {
  background: var(--negative-light);
  outline: 1px dashed var(--negative);
}

.field span {
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
}
.field [data-var] {
  border-radius: 4px;
  background: var(--grey-2-lines);
  outline: 1px solid var(--grey-3-outlines);
  padding: 2px 0;
}
.field [data-noarg] {
  background: var(--negative-light);
  outline: 1px dashed var(--negative);
}
</style>
