<script lang="ts" setup generic="U extends { id: string }, T extends U">
import { Button, Card, Column, Modal, Row, Spinner, TextInput, Select } from '@madxnl/dodo-ui'
import { ref, computed } from 'vue'
import ShowMoreButton from './ShowMoreButton.vue'
import TwinIcon from './TwinIcon.vue'

const model = defineModel<U | null>()
const search = defineModel<string>('search')

const props = defineProps<{
  showMore: (() => Promise<void>) | null
  items: T[] | undefined
  placeholder: string
  getCurrentText: (item: U) => string
  disabled?: boolean
  actionEdit?: (value: U) => void
  actionCreate?: () => void
}>()

const open = ref(false)

const options = computed(() => {
  const label = model.value ? props.getCurrentText(model.value) : ''
  return [{ label, value: model.value?.id ?? null }]
})

function isSelected(item: T) {
  return model.value?.id === item.id
}

function clickSelectItem(item: T) {
  model.value = { ...item }
  closeModal()
}

function closeModal() {
  open.value = false
}
</script>

<template>
  <Column gap="xs">
    <Row gap="xs">
      <Select
        :model-value="model?.id"
        :options="options"
        :placeholder="placeholder"
        :disabled="disabled ?? false"
        style="flex-grow: 1"
        @click="open = true"
        @mousedown.prevent
      />
    </Row>

    <Row v-if="actionEdit || actionCreate" justify="end">
      <Button v-if="actionEdit" variant="link" :disabled="!model" @click="actionEdit(model!)">
        <TwinIcon icon="Edit" />
        Edit
      </Button>

      <Button v-if="actionCreate" variant="link" @click="actionCreate">
        <TwinIcon icon="Plus" />
        Create
      </Button>
    </Row>

    <Modal v-slot="{ close }" :open="open" size="L" padding="0" gap="0" @close="closeModal">
      <Row padding="l" :class="$style.heading">
        <Row grow>
          <h2>{{ placeholder }}</h2>
        </Row>
        <TextInput v-model="search" type="search" placeholder="Type to search" autofocus />
        <Button variant="link" square @click="close()">
          <TwinIcon icon="Cross" />
        </Button>
      </Row>
      <hr />

      <div :class="$style.content">
        <div :class="$style.resultsBox">
          <Column padding="l" align="center">
            <Spinner v-if="items == null" />
            <p v-else-if="items.length === 0">No results found</p>
            <template v-for="item in items" v-else :key="item.id">
              <Card style="width: 100%" hoverable :active="isSelected(item)" @click="clickSelectItem(item)">
                <slot name="item" :item="item">
                  {{ item.id }}
                </slot>
              </Card>
            </template>

            <ShowMoreButton :show-more="showMore" />
          </Column>
        </div>
      </div>
    </Modal>
  </Column>
</template>

<style module>
.resultsBox {
  display: grid;
  gap: var(--dodo-spacing-m);
  padding-bottom: var(--dodo-spacing-l);
  height: 500px;
  overflow-y: auto;
  border-radius: 4px;
  align-content: start;

  & > * {
    overflow: initial; /* temp fix card overflow */
  }
}

.heading {
  padding-top: var(--dodo-spacing-m) !important;
  padding-bottom: var(--dodo-spacing-m) !important;
}

.content {
  background-color: var(--grey-1-altbg);
}
</style>
