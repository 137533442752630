<script lang="ts" setup generic="T">
import { Button, Column, Row } from '@madxnl/dodo-ui'

defineProps<{
  options: {
    name: string
    value: T
  }[]
  description?: string
}>()

const modelValue = defineModel<T>()

function update(option: T | undefined) {
  if (option == null) return
  modelValue.value = option
}
</script>

<template>
  <Row gap="l">
    <Column gap="s">
      <Row gap="s" :class="$style.SegmentedControl">
        <template v-for="option in options" :key="option">
          <Button
            :variant="modelValue === option.value ? undefined : 'clear'"
            :color="modelValue === option.value ? 'primary' : undefined"
            @click="update(option.value)"
          >
            {{ option.name }}
          </Button>
        </template>
      </Row>
    </Column>
    <Column v-if="description">
      <small :class="$style.description">{{ description }}</small>
    </Column>
  </Row>
</template>

<style module>
.SegmentedControl {
  background-color: var(--grey-3-outlines);
  padding: 2px;
  border-radius: 8px;
  overflow: hidden;
}

.description {
  color: var(--grey-4-disabled);
}
</style>
