import { useApiClient } from '@/api'
import { useSimpleMessage } from '@/ui/composables'
import { computed } from 'vue'

export function useResetElasticSearch() {
  const isLocalDevelop = location.hostname === 'localhost'
  const { headers } = useApiClient()
  const { showMessage } = useSimpleMessage()

  async function doResetElasticSearch() {
    // Reset elastic search in local development
    const apiUrl = import.meta.env.VITE_APP_API_URL as string
    const endpoint = apiUrl.replace('graphql', 'reset-es-node')
    const response = await fetch(endpoint, { headers: headers.value })
    const result = await response.text()
    showMessage(result)
  }

  const resetElasticSearch = computed(() => {
    return isLocalDevelop ? doResetElasticSearch : null
  })

  return { resetElasticSearch }
}
