<script setup lang="ts">
import { onMounted } from 'vue'

defineProps<{
  src: string
}>()

onMounted(loadVimeoPlayer)

const vimeoScriptUrl = 'https://player.vimeo.com/api/player.js'

async function loadVimeoPlayer() {
  const id = 'vimeo-player-script'
  if (!document.getElementById(id)) {
    const script = document.createElement('script')
    script.id = id
    document.body.appendChild(script)

    await new Promise<void>((resolve, reject) => {
      script.src = vimeoScriptUrl
      script.addEventListener('load', () => resolve())
      script.addEventListener('error', (err) => reject(err))
    })
  }
}
</script>

<template>
  <div :class="$style.VimeoVideo">
    <div :class="$style.aspect">
      <iframe :src="src" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<style module>
/* Responsive video container that maintains aspect ratio */
.VimeoVideo {
  max-width: 100%;
}
.aspect {
  width: 800px;
  max-width: 100%;
  background: red;
  padding-top: calc(360 / 640 * 100%);
  position: relative;
}
.aspect iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
</style>
