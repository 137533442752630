<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  gridSpacing: number
}>()

const style = computed(() => {
  const spacing = props.gridSpacing
  const dotRect = `<circle fill='black' r='2' cx='2' cy='2' />`
  const svg = `<svg xmlns='http://www.w3.org/2000/svg' width='${spacing}' height='${spacing}'>${dotRect}</svg>`
  const backgroundImage = `url("data:image/svg+xml;utf8,${svg}")`
  return { backgroundImage }
})
</script>
<template>
  <div :class="$style.bigDots" :style="style"></div>
</template>
<style module>
.bigDots {
  position: absolute;
  z-index: -1;
  --size: -8000px;
  top: var(--size);
  bottom: var(--size);
  left: var(--size);
  right: var(--size);
  opacity: 0.05;
  background-position: -2px -2px;
}
</style>
