export const workflowRoutes = {
  workflows: { name: 'workflowsRoute', path: '' },
  workflowData: { name: 'workflowDataRoute', path: '' },
}

export const workflowLinks = {
  workflows: () => ({ name: workflowRoutes.workflows.name }),
  workflowData: (workflowId: string) => ({
    name: workflowRoutes.workflowData.name,
    params: { workflowId },
  }),
}
