import { useApiClient } from '@/api'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

type AuthorizedIntent = { id: string }

type ChargbeeInstance = {
  setPortalSession: (session: ChargbeePortal) => void
  createChargebeePortal: () => ChargbeePortal
  handlePayment: (
    paymentMethod: string,
    options: { paymentIntent: () => Promise<unknown>; paymentInfo: any },
  ) => Promise<AuthorizedIntent>
}

type ChargbeePortal = {
  openSection: (options: { sectionType: string }) => void
}

type Chargebee = {
  init: (options: { site: string; publishableKey: string }) => ChargbeeInstance
  getPortalSections: () => { [key: string]: string }
}

declare const Chargebee: undefined | Chargebee

const VITE_CHARGEBEE_SITE = import.meta.env.VITE_CHARGEBEE_SITE
if (!VITE_CHARGEBEE_SITE) throw new Error('VITE_CHARGEBEE_SITE is not defined')
const VITE_CHARGEBEE_KEY = import.meta.env.VITE_CHARGEBEE_KEY
if (!VITE_CHARGEBEE_KEY) throw new Error('VITE_CHARGEBEE_KEY is not defined')

const chargebeeInstance = ref<ChargbeeInstance | null>(null)
const chargebeePortal = ref<ChargbeePortal | null>(null)
let initPromise: Promise<Chargebee> | null = null

export function useChargebee() {
  const { client } = useApiClient()

  if (getCurrentInstance()) {
    onMounted(initChargebee)
  }

  const chargebeeIsLoading = computed(() => chargebeeInstance.value == null)

  async function initChargebee() {
    if (!initPromise) {
      initPromise = new Promise<Chargebee>((resolve, reject) => {
        const script = document.createElement('script')
        script.src = 'https://js.chargebee.com/v2/chargebee.js'
        script.addEventListener('load', () => resolve(Chargebee as Chargebee))
        script.addEventListener('error', (err) => reject(err))
        document.body.appendChild(script)
      })
    }
    return await initPromise
  }

  async function getChargebeeInstance() {
    // get or create Chargebee instance
    const chargebee = await initChargebee()
    chargebeeInstance.value ??= chargebee.init({ site: VITE_CHARGEBEE_SITE, publishableKey: VITE_CHARGEBEE_KEY })
    return chargebeeInstance.value
  }

  async function createChargebeePortal() {
    const instance = await getChargebeeInstance()
    const result = await client.createChargebeePortalSession()
    const portal = result.createChargebeePortalSession as ChargbeePortal
    if (!portal) throw new Error('Failed to create chargebee portal session')
    instance.setPortalSession(portal)
    chargebeePortal.value = instance.createChargebeePortal()
  }

  async function openChargebeePortal(section: 'BILLING_HISTORY' | 'PAYMENT_SOURCES') {
    const chargebee = await initChargebee()
    const sections = chargebee.getPortalSections()
    const sectionType = sections[section]!
    chargebeePortal.value!.openSection({ sectionType })
  }

  return { createChargebeePortal, openChargebeePortal, chargebeeIsLoading, getChargebeeInstance }
}
