import { Plugin } from 'vue'
import { Router } from 'vue-router'
import { Navigation } from '../navigation'
import { authLinks, authRoutes } from './routes'
import LoginPage from './components/LoginPage.vue'
import LogoutPage from './components/LogoutPage.vue'
import SignupPage from './components/SignupPage.vue'
import EmailVerificationPage from './components/EmailVerificationPage.vue'
import ActivationRequiredPage from './components/ActivationRequiredPage.vue'
import InviteAcceptPage from './components/InviteAcceptPage.vue'
import ForgotPasswordPage from './components/ForgotPasswordPage.vue'
import SetPasswordPage from './components/SetPasswordPage.vue'

export const auth: Plugin<{ router: Router; navigation: Navigation }> = {
  install: (app, { router, navigation }) => {
    // Register auth routes
    router.addRoute({
      name: 'auth',
      path: '/auth',
      children: [
        { ...authRoutes.login, component: LoginPage },
        { ...authRoutes.signup, component: SignupPage },
        { ...authRoutes.logout, component: LogoutPage },
        { ...authRoutes.expired, component: LogoutPage },
        { ...authRoutes.emailVerification, component: EmailVerificationPage },
        { ...authRoutes.activationRequired, component: ActivationRequiredPage },
        { ...authRoutes.inviteAccept, component: InviteAcceptPage },
        { ...authRoutes.forgotPassword, component: ForgotPasswordPage },
        { ...authRoutes.setPassword, component: SetPasswordPage },
      ],
      redirect: authLinks.login(),
    })
  },
}
