<script lang="ts" setup>
import { LargeModalLayout } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, FormItem, TextInput } from '@madxnl/dodo-ui'
import { useMemberInviteForm } from '../composables'

defineProps<{
  open: boolean
}>()

const emit = defineEmits<{
  close: []
  inviteCreated: []
}>()

const { data, submit, errors } = useMemberInviteForm()
const { showMessage } = useSimpleMessage()

async function clickSubmit() {
  const result = await submit()
  if (!result) return // Validation failed
  emit('close')
  emit('inviteCreated')
  showMessage('Invite sent')
}

function handleUpdateEmail(email: string | null) {
  data.email = email?.trim() ?? ''
}
</script>

<template>
  <LargeModalLayout :open="open" title="Invite member" @close="$emit('close')">
    <template #content>
      <FormItem label="Invitee email" :error="errors.email">
        <TextInput v-model="data.email" @update:model-value="handleUpdateEmail" />
      </FormItem>

      <FormItem label="Invitee name (optional)" :error="errors.name">
        <TextInput v-model="data.name" />
      </FormItem>
    </template>

    <template #footer="{ close }">
      <Button @click="close()">Cancel</Button>
      <Button color="primary" variant="solid" type="submit" @click="clickSubmit()">Submit</Button>
    </template>
  </LargeModalLayout>
</template>
